import {
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
  } from "@ant-design/icons";
  import {
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    Tabs,
    Tooltip,
    DatePicker
  } from "antd";
  import TextArea from "antd/lib/input/TextArea";
  import React, { useEffect, useState } from "react";
  import unite from "../../../helpers/unite";
  import CategorieService from "../../../services/categorie.service";
  import FournisseurService from "../../../services/fournisseur.service";
  import MarqueService from "../../../services/marque.service";
  import ProduitService from "../../../services/produit.service";
  import TvaService from "../../../services/tva.service";
  import groupeClientService from "../../../services/groupeClient.service";
  import formuleGratuiteService from "../../../services/formuleGratuite.service";
  import AppTable from "../../uiHelpers/AppTabe";
  import AddCategorieModalForm from "../parametres/tabs/AddCategorieModalForm";
  import AddMarqueModalForm from "../parametres/tabs/AddMarqueModalForm";
  import AddTvaModalForm from "../parametres/tabs/AddTvaModalForm";
  import AddFournisseurModalForm from "./AddFournisseurModalForm";
  import moment from "moment";
  
  const AddProductModalFormV2 = ({showModal,productAdded,modalType,modalTitle}) => {
    const [produitForm] = Form.useForm();
    const [produitModal, setProduitModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [switchValue, setSwitchValue] = useState("unchecked");
    const [isProduct, setProduitType] = useState(false);
    const [current, setCurrent] = useState(0);
    const [marque, setMarques] = useState([]);
    const [categorie, setCategories] = useState([]);
    const [fournisseur, setFournisseur] = useState([]);
    const [tva, setTvas] = useState([]);
    const [prixAchat, setPrixAchat] = useState({ ttc: 0, ht: 0 });
    const [prixVente, setPrixVente] = useState({ ttc: 0, ht: 0 });
    const [marges, setMarges] = useState({ margeBrute: 0, margeReel: 0 });
    const [proprietereVal, setProprietreVal] = useState(true);
  
    const [categorieModal, setCategorieModal] = useState(false);
    const [marqueModal, setMarqueModal] = useState(false);
    const [tvaModal, setTvaModal] = useState(false);
    const [fournisseurModal, setFournisseurModal] = useState(false);
    const [groupesClients, setGroupesClient] = useState([]);
    const [showDates, setShowDates] = useState(false);
    const [formuleGratuite, setFormuleGratuite] = useState([]);
    const [finalTabKey, setFinalTabKey] = useState(1);


    useEffect( () => {
      async function fetchData() {
        await loadTvas();
        await loadMarques();
        await loadCategories();
        await loadFournisseur();
        await loadGroupesClients();
        await loadFormuleGrauite();
        await setCompositePorducts()
        await loadProduits();
      }
      fetchData();
      return () => {
        clearStates();
      };
    }, [modalType]);
  
    const clearStates = () => {
      setDataTable([]);
      setPrixAchat({ ttc: 0, ht: 0 });
      setPrixVente({ ttc: 0, ht: 0 });
      setMarges({ margeBrute: 0, margeReel: 0 });
  
    };

    const loadProduits = async () => {
        setLoading(true);
        const response = await ProduitService.getProduitsForDashboard();
        if (response.status === 200 || 204) initTableData(response.data);
        else message.error("Une erreur est survenue ! ");
        setLoading(false);
    };

    const formatteData = (produits) => {
        for (const produit of produits) {
          produit.showType = getProductType(produit.type);
          produit.uniteAchat = Number(produit.produit_achats[0]?.unite) ?? null;
          produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
          produit.htAchat = produit.produit_achats[0]?.prixHT;
          produit.showFournisseur =
            produit.produit_achats[0]?.fournisseur?.raison_sociale ??
            produit.showType +" propriétaire";
          produit.tvaAchat = produit.produit_achats[0]?.tva;
          produit.tvaVente = produit.produit_ventes[0].tva;
          produit.uniteVente = Number(produit.produit_ventes[0].unite);
          produit.ttcVente = produit.produit_ventes[0].prixTTC;
          produit.htVente = produit.produit_ventes[0].prixHT;
        }
        return produits;
    };

    const getProductType = ( type ) => {
        if( type === 0 ) return "Produit Simple";
        else if( type === 1 ) return "Service";
        else return "Produit Composé";
    }
    
    const initTableData = (produits) => {
        produits = formatteData(produits);
        sortByUpdatedAtd(produits)
        setDataTable(produits);
    };

    const sortByUpdatedAtd = (data) => {
        return data.sort((a,b)=>{
             return new Date(a.updatedAt).getTime() - 
             new Date(b.updatedAt).getTime()
         }).reverse();
    }  
    
    const setCompositePorducts = () => {
        const composition_produits = produitForm.getFieldValue("composition_produits") ?? [];
        if( composition_produits.length === 0 && modalType === 2 ){
          const placeholderData = {
            composed_from: null,
            prixUnitaireHT: null,
            Quantité: null,
            TVA: null,
            prixTotalTTC: null
          }
          composition_produits.push(placeholderData, placeholderData);
        }
    
    
        produitForm.setFieldsValue({composition_produits:composition_produits});
    }
  
    const loadFournisseur = async () => {
      setLoading(true);
      const response = await FournisseurService.getFournisseurs();
      if (response.status === 200 || 204) setFournisseur(response.data);
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    const loadMarques = async () => {
      setLoading(true);
      const response = await MarqueService.getActiveMarques();
      if (response.status === 200 || 204) setMarques(response.data);
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
  
    const loadCategories = async () => {
      setLoading(true);
      const response = await CategorieService.getActiveCategories();
      if (response.status === 200 || 204) setCategories(response.data);
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    const loadTvas = async () => {
      setLoading(true);
      const response = await TvaService.getActiveTva();
      if (response.status === 200 || 204) setTvas(response.data);
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
  
    const prixVenteChanged = () => {
      const produit = produitForm.getFieldsValue();
      const prixVenteInput = produit.prixVente;
      const taxeId = produit.tvaVente;
      const taxe = tva.find((e) => e.id === taxeId)?.valeur ?? 1
      const ht = Number(prixVenteInput).toFixed(3);
      const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
      setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
      const marge_ht = ((prixVenteInput- prixAchat.ht) / prixAchat.ht) * 100;
      produitForm.setFieldsValue({ marge_ht: marge_ht });
      setMargesValues()
    }
  
    const handleCancel = () => {
      clearData();
      productAdded(false);
      setFinalTabKey(1)
    };
  
  
    const closingModal = () => {
      setProduitModal(false);
      setLoading(false);
      setSwitchValue("unchecked");
      clearData();
    };
  
    const clearData = () => {
      produitForm.resetFields();
      produitForm.setFieldsValue({ type: false });
      setProduitType(false);
      setPrixAchat({ ttc: 0, ht: 0 });
      setPrixVente({ ttc: 0, ht: 0 });
      setMarges({ margeBrute: 0, margeReel: 0 });
      setEditMode(false);
      setUpdateId(null);
      produitForm.setFieldsValue({ composition_produits: [] });  
      setShowDates(false)
    };
  
  
    const switchChange = () => {
      setProduitType(produitForm.getFieldValue("type"));
    };
  
    const calculateAchat = () => {
      if (
        !produitForm.getFieldValue("typePrix") ||
        !produitForm.getFieldValue("prixFournisseur")
      )
        return;
      const prixType = produitForm.getFieldValue("typePrix");
      const prix = produitForm.getFieldValue("prixFournisseur");
      const taxe =
        tva.find((e) => e.id === produitForm.getFieldValue("tvaAchat"))?.valeur ??
        0;
      const { ttc, ht } = calculateTotaux(prixType, prix, taxe);
      setPrixAchat((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
    };
    
    const calculateTotaux = (type, prix, taxe) => {
      if (type === "HT") {
        const ht = Number(prix).toFixed(3);
        const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
        return { ttc, ht };
      } else {
        const ttc = Number(prix).toFixed(3);
        const ht = (ttc / (1 + taxe / 100)).toFixed(3);
        return { ttc, ht };
      }
    };
    const calculateMarges = (venteHt, achatHt) => {
      const margeBrute = (((venteHt - achatHt) / venteHt) * 100).toFixed(2);
      const margeReel = (((venteHt - achatHt) / achatHt) * 100).toFixed(2);
      return { margeBrute, margeReel };
    };
    const setProprietereValue = () => {
      setProprietreVal(produitForm.getFieldValue("proprietere"));
    };
    const openAddCategorieModal = () => {
      setCategorieModal(true);
    };
    const onCategorieModalClose = async (action) => {
      if (action) await loadCategories();
      setCategorieModal(false);
    };
    const openAddMarqueModal = () => {
      setMarqueModal(true);
    };
    const onMarqueModalClose = async (action) => {
      if (action) await loadMarques();
      setMarqueModal(false);
    };
    const openAddTvaModal = () => {
      setTvaModal(true);
    };
    const onTvaModalClose = async (action) => {
      if (action) await loadTvas();
      setTvaModal(false);
    };
    const openAddFournisseurModal = () => {
      setFournisseurModal(true);
    };
    const onFournisseurModalClose = async (action) => {
      if (action) await loadFournisseur()
      setFournisseurModal(false);
    };
  
    const addProduit = async (values) => {
      setLoading(true);
      var data = values;
      data.type = data.type ? data.type : false;
  
      const taxeVente = tva.find(
        (e) => e.id === produitForm.getFieldValue("tvaVente")
      )?.valeur;
      const taxeAchat = tva.find(
        (e) => e.id === produitForm.getFieldValue("tvaAchat")
      )?.valeur;
      const fournisseurId = produitForm.getFieldValue("fournisseurId");
      const produit_ventes = {
        tva: taxeVente,
        unite: data.uniteVente,
        prixTTC: prixVente.ttc,
        prixHT: prixVente.ht,
      };
      data.produit_ventes = produit_ventes;
  
      const produit_achats = {
        tva: taxeAchat,
        unite: data.uniteAchat,
        prixTTC: prixAchat.ttc,
        prixHT: prixAchat.ht,
        fournisseurId: fournisseurId,
      };
      data.produit_achats = produit_achats;
  
      const response = editMode
        ? await ProduitService.updateProduit(updateId, values)
        : await ProduitService.addProduit(values);
      if (response.status === 200 || 204) {
        if (editMode) message.info("Mise à jour avec succès");
        else message.info("Produit ajouté avec succès");
      } else message.error("Une erreur est survenue ! ");
      closingModal();
      productAdded(true);
      setLoading(false);
  
    };
  
  
    const margeChanged = () => {
      const product = produitForm.getFieldsValue();
      const prixVenteHt = Number(prixAchat.ht) + ( Number(prixAchat.ht) * ( product.marge_ht / 100 ) );
      produitForm.setFieldsValue({
        tvaVente : product.tvaAchat,
        uniteVente : product.uniteAchat,
        prixVente : prixVenteHt,
        VentetypePrix : "HT"
      })
      const ht = Number(prixVenteHt).toFixed(3);
      const taxe = tva.find((e) => e.id === produitForm.getFieldValue("tvaVente"))?.valeur ?? 0;
      const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
      setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
      setMargesValues();
    }
    const setMargesValues = () => {
      const { margeBrute, margeReel } = calculateMarges(
        prixVente.ht,
        prixAchat.ht
      );
      setMarges({ margeBrute, margeReel });
    }

    const productChanged = ( index ) => {
        const composition_produits = produitForm.getFieldValue("composition_produits");
        const produitId = composition_produits[index].composed_from;
        const selectedProduct = dataTable.find( p => p.id === produitId);
        composition_produits[index] = { 
          composed_from: produitId , 
          prixUnitaireHT : selectedProduct.produit_achats[0].prixHT , 
          qte : 1 , 
          tva : selectedProduct.produit_achats[0].tva ,  
          prixTotalHT : selectedProduct.produit_achats[0].prixHT , 
          prixTotalTTC : selectedProduct.produit_achats[0].prixTTC 
        }
        produitForm.setFieldsValue(composition_produits);
        calculatePrixAchat()
      }
    
    const prixChanged = ( index ) => {
        const composition_produits = produitForm.getFieldValue("composition_produits");
        const product = composition_produits[index];
        product.prixTotalHT = Number( product.prixUnitaireHT * product.qte ).toFixed(3);
        product.prixTotalTTC = Number( Number(product.prixTotalHT) +  Number(( product.prixTotalHT  * product.tva / 100 ))  ).toFixed(3)  
        composition_produits[index] = product;
        produitForm.setFieldsValue(composition_produits);
        calculatePrixAchat()
    }
  
    const calculatePrixAchat = () => {
        const composition_produits = produitForm.getFieldValue("composition_produits");
        let prixHTAchat = 0;
        let prixTTCAchat = 0;
        let tva = 0;
        for (const element of composition_produits) {
          prixHTAchat += Number(element.prixTotalHT);
          prixTTCAchat += Number(element.prixTotalTTC);
          tva += element.tva;
        }
        tva = tva / composition_produits.length;
        produitForm.setFieldsValue({tvaAchat : tva , tvaVente: tva});
        setPrixAchat((prevState) => ({ ...prevState, ttc: Number(prixTTCAchat).toFixed(3), ht:  Number(prixHTAchat).toFixed(3) }));
    }

    const isQteMAxGtMin = () => {
        const qteMin = produitForm.getFieldValue('qteMinVente')
        const qteMax = produitForm.getFieldValue('qteMaxVente')
        if (qteMax > qteMin) return true
        else return false
      }
    
    const isDateDebGtFin = () => {
        const applicationFormuleDebut = produitForm.getFieldValue('applicationFormuleDebut')
        const applicationFormuleFin = produitForm.getFieldValue('applicationFormuleFin')
        if (moment(applicationFormuleFin).isBefore(applicationFormuleDebut)) return true
        else return false
    }

    const formuleSelectChanged = () => {
        const formuleId = produitForm.getFieldValue("formuleGratuiteId");
        // if( formuleId !== 1 ) setShowDates(true);
        if( formuleId ) setShowDates(true);
        else  setShowDates(false);
    }

    const loadGroupesClients = async () => {
        setLoading(true);
        const response = await groupeClientService.getActiveGroupeClients();
        if (response.status === 200 || 204) setGroupesClient(response.data);
        else message.error("Une erreur est survenue ! ");
        setLoading(false);
      }
      
    const loadFormuleGrauite = async () => {
        setLoading(true);
        const response = await formuleGratuiteService.getActiveFormuleGratuites();
        if (response.status === 200 || 204) setFormuleGratuite(response.data);
        else message.error("Une erreur est survenue ! ");
        setLoading(false);
    }

      // modalType !== 2 && tabKey == 1:
  //    - modalType !== 2 : not a PF modal (has composite tab)
  //    - tabKey == 1 : normalment 2 (composite tab),or this trigger when clicking so when tabkey=2 it will read tabkey = 1... 
  //  -> to by pass the case where : Modal not for PC so dont have composite tab -> bypass tab with key = 2
  const handleTabsChange = (key) => {
    let tabKey = finalTabKey
    isNaN(key)  ? modalType !== 2 && tabKey == 1 ? setFinalTabKey(3) : setFinalTabKey((prev)=>prev+1) 
                : setFinalTabKey(Number(key)) 
  }

    return (
      <Spin spinning={isLoading} size="large">
     
        <Form layout="vertical" form={produitForm} onFinish={addProduit}>
        <Modal
              visible={showModal}
              onOk={finalTabKey == 5 ? produitForm.submit : handleTabsChange}
              onCancel={handleCancel}
              getContainer={false}
              okText={finalTabKey == 5 ? "Enregistrer" : "Suivant"}
              cancelText="Annuler"
              centered
              width={1100}
              title={editMode ? "Modifier le " : "Ajouter un " + modalTitle }
            >
              <Form layout="vertical" form={produitForm} onFinish={addProduit}>
                <Tabs defaultActiveKey="1" activeKey={`${finalTabKey}`}  onChange={handleTabsChange}>
                  <Tabs.TabPane  tab={"Informations"} key="1" >
                      <Form.Item className="hideen" name="type" >
                          <Input type="text" />
                      </Form.Item>
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Libellé"
                          name="libelle"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Row>
                          <Col span={20} className="pl-2">
                            <Form.Item
                              label="Catégorie"
                              name="categorieId"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                },
                              ]}
                            >
                              <Select size="large" dropdownStyle = {{ position: "fixed" }}>
                                {categorie.map((c) => (
                                  <Select.Option key={c.id} value={c.id}>
                                    {c.nom}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4} className="pr-2">
                            <Form.Item label=" ">
                              <Tooltip title="Ajouter une catégorie">
                                <Button
                                  className="margin-05"
                                  type=""
                                  onClick={() => openAddCategorieModal()}
                                  icon={<PlusOutlined />}
                                  size={"large"}
                                />
                              </Tooltip>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Référence Interne"
                          name="reference_interne"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                    </Row>
                    {modalType !== 1 &&
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Code à barre"
                          name="code"
                          rules={[
                            {
                              message:"Maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Row>
                          <Col span={20} className="pl-2">
                            <Form.Item
                              label="Marque"
                              name="marqueId"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <Select size="large" dropdownStyle = {{ position: "fixed" }}>
                                {marque.map((m) => (
                                  <Select.Option key={m.id} value={m.id}>
                                    {m.nom}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4} className="pr-2">
                            <Form.Item label=" ">
                              <Tooltip title="Ajouter une marque">
                                <Button
                                  className="margin-05"
                                  onClick={() => openAddMarqueModal()}
                                  type=""
                                  icon={<PlusOutlined />}
                                  size={"large"}
                                />
                              </Tooltip>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Référence constructeur"
                          name="reference_constructeur"
                          rules={[
                            {
                              message:"Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                    </Row>
                    }
                    {  modalType !== 2 ?
                      <div>
                        <Row>
                          <Col span={24} className="p-2">
                            {modalType === 1 ? <b>  Coût du service :  </b> : <b> Prix Achat : </b> } 
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} className="px-2">
                            <Row>
                              <Col span={21} className="pl-2">
                                <Form.Item
                                  label="TVA"
                                  name="tvaAchat"
                                  rules={[
                                    {
                                      required: true,
                                      message:"Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    onChange={() => calculateAchat()}
                                    dropdownStyle = {{ position: "fixed" }}
                                  >
                                    {tva.map((t) => (
                                      <Select.Option key={t.id} value={t.valeur}>
                                        {t.titre} - {t.valeur} %
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={3} className="pr-2">
                                <Form.Item label=" ">
                                  <Tooltip title="Ajouter une TVA ">
                                    <Button
                                      className="margin-05"
                                      onClick={() => openAddTvaModal()}
                                      type=""
                                      icon={<PlusOutlined />}
                                      size={"large"}
                                    />
                                  </Tooltip>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label="Unité"
                              name="uniteAchat"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                onChange={() => calculateAchat()}
                                dropdownStyle = {{ position: "fixed" }}
                              >
                                {unite.map((u) => (
                                  <Select.Option key={u.id} value={u.id}>
                                    {u.code_fr}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label={modalTitle === "Produit Simple" ? <> Prix Fournisseur </> : <> Coût</>}
                              name="prixFournisseur"
                              rules={[
                                {
                                  required: true,
                                  message:"Champ obligatoire avec maximum de caractère 100 !",
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} className="px-2">
                            <Form.Item
                              label="Type"
                              name="typePrix"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire ",
                                },
                              ]}
                            >
                              <Radio.Group onChange={() => calculateAchat()}>
                                <Radio value={"TTC"}>TTC</Radio>
                                <Radio value={"HT"}>HT</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span={6} className="px-2">
                            <Form.Item label="Résultat:">
                              Prix HT : {prixAchat.ht}<br />
                              Prix TTC : {prixAchat.ttc} 
                              <br />
                              <Input type={"hidden"}></Input>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div> : <></>
                    }
                  </Tabs.TabPane>
                  { modalType === 2 &&
                    <Tabs.TabPane tab={"Composition "} key="2" forceRender={true}>
                      <Row>
                        <Col span={24}>
                          <b className="p-2">Composition :</b>
                        </Col>

                        <Form.List
                          name="composition_produits"
                          label="Liste des produits"
                        >
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <>
                                  <Col span={9} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "composed_from"]}
                                      label={ key === 0 ? "Produit" : "Embalage"}
                                      rules={[{ required: true, message: "Champ obligatoire!", } ]}
                                    >
                                      <Select size="large" onChange={() => productChanged(key)} dropdownStyle = {{ position: "fixed" }}>
                                        {/* {dataTable.filter( (e) =>  ( key === 0 ? e.categorieId!==2 : e.categorieId === 2 ) ).map(( prod) => ( */}
                                        {dataTable.map(( prod) => ( 
                                          <Select.Option key={prod.id} value={prod.id}>
                                            {prod.showType} -  {prod.libelle}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                        {...restField}
                                        name={[name, "prixUnitaireHT"]}
                                        label="Prix Unitaire HT"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Champ obligatoire!",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          onChange={() => prixChanged(key)}
                                          size="large"
                                          style={{ width: "100%" }}
                                          min={0}
                                          max={10000000}
                                        />
                                      </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qte"]}
                                      label="Quantité"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        onChange={() => prixChanged(key)}
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "tva"]}
                                      label="TVA"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixTotalHT"]}
                                      label="Prix Total HT"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                        {...restField}
                                        name={[name, "prixTotalTTC"]}
                                        label="Prix Total TTC"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Champ obligatoire!",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          disabled
                                          size="large"
                                          style={{ width: "100%" }}
                                          min={0}
                                          max={100000000}
                                        />
                                      </Form.Item>
                                  </Col>
                                </>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </Row>
                      <Col span={22} className="px-2">
                        <div className="d-flex flex-row-reverse">
                          <Form.Item label="Résultat:">
                            Prix HT : {prixAchat.ht}<br />
                            Prix TTC : {prixAchat.ttc} 
                            <br />
                          </Form.Item>
                        </div>
                      </Col>
                      <div className="hideen">
                        <Form.Item name="tvaAchat" >
                            <Input type="text" />
                        </Form.Item>
                      </div>
                    </Tabs.TabPane>
                  }
                  <Tabs.TabPane tab={"Prix de vente"} key="3" forceRender={true}>
                    <Row>
                      <Col span={12} className="px-2 my-2">
                        <b className="px-2">Prix de vente :</b>
                      </Col>
                    </Row>
                    <Row>
                        <Col span={16} className="px-2">
                          <Row>
                            <Col span={12} className="px-2">
                              <Form.Item
                                label="Marge HT (%)"
                                name="marge_ht"
                                rules={[
                                  {
                                    required: true,
                                    message: "Champ obligatoire !",
                                  },
                                ]}
                              >
                              <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                                onChange={() => margeChanged()}
                              />
                              </Form.Item>
                            </Col>
                            <Col span={12} className="px-2">
                              <Form.Item
                                label="Prix Vente HT"
                                name="prixVente"
                                rules={[
                                  {
                                    required: true,
                                    message:"Champ obligatoire !",
                                  },
                                ]}
                              >
                                <InputNumber
                                  size="large"
                                  style={{ width: "100%" }}
                                  min={0}
                                  max={99999999999}
                                  onChange={() => prixVenteChanged()}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={8} className="px-4">
                        <Form.Item label="Résultat:">
                          Prix HT : {prixVente.ht} <br />
                          Prix TTC : {prixVente.ttc} <br />
                          <Input type={"hidden"}></Input>
                        </Form.Item>
                      </Col>
                      
                      </Row>
                    <Row>

                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Type"
                          name="VentetypePrix"
                          style={{ visibility: "hidden" }}
                        >
                          <Radio.Group>
                            <Radio value={"TTC"}>TTC</Radio>
                            <Radio value={"HT"}>HT</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                          <Form.Item
                            label="Tva"
                            name="tvaVente"
                            style={{ visibility: "hidden" }}
                          >
                            <Select  size="large" disabled>
                              {tva.map((t) => (
                                <Select.Option key={t.id} value={t.valeur}>
                                  {t.titre} - {t.valeur} %
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Unité"
                          name="uniteVente"
                          style={{ visibility: "hidden" }}
                        >
                          <Select
                            className="w-100"
                            size="large"
                            disabled
                            onChange={() => calculateAchat()}
                          >
                            {unite.map((u) => (
                              <Select.Option
                                key={u.id}
                                value={u.id}
                                className="w-100"
                              >
                                {u.code_fr}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Description"} key="4" forceRender={true} >
                    <Row>
                      <Col span={12} className="px-2">
                        <Form.Item
                          name="proprietere"
                          label="Votre entreprise est Propriétaire?"
                          rules={[
                            { required: true, message: "Champ obligatoire !" },
                          ]}
                        >
                          <Radio.Group onChange={() => setProprietereValue()}>
                            <Radio value={false}>Non</Radio>
                            <Radio value={true}>Oui</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        {!proprietereVal && (
                          <Col span={24} className="px-2">
                            <Row>
                              <Col span={21} className="pl-2">
                                <Form.Item
                                  label="Fournisseur"
                                  name="fournisseurId"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select size="large" className="w-100" dropdownStyle = {{ position: "fixed" }}>
                                    {fournisseur.map((f) => (
                                      <Select.Option key={f.id} value={f.id}>
                                        {f.nom} {f.prenom}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                                <Col span={3} className="pr-2">
                                    <Form.Item label=" ">
                                        <Tooltip title="Ajouter un fournisseur">
                                        <Button
                                            className="margin-05"
                                            onClick={() => openAddFournisseurModal()}
                                            type=""
                                            icon={<PlusOutlined />}
                                            size={"large"}
                                        />
                                        </Tooltip>
                                        <AddFournisseurModalForm
                                        showModal={fournisseurModal}
                                        fournisseurAdded={(action) =>
                                            onFournisseurModalClose(action)
                                        }
                                        ></AddFournisseurModalForm>
                                    </Form.Item>
                                </Col>
                              {/* <Col span={3} className="pr-2">
                                <Form.Item label=" ">
                                  <Tooltip title="Ajouter un fournisseur">
                                    <Button
                                      className="margin-05"

                                      onClick={() => openAddFournisseurModal()}
                                      type=""
                                      icon={<PlusOutlined />}
                                      size={"large"}
                                    />
                                  </Tooltip>

                                </Form.Item>
                              </Col> */}
                            </Row>
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="px-2">
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec maximum de caractère 255 !",
                              max: 254,
                            },
                          ]}
                        >
                          <TextArea type="text" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Caractéristique de vente"} key="5" forceRender={true}>
                    <Row>
                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          name="groupesClients"
                          label="Le produit peut être vendu aux groupes "
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            size="large"
                            dropdownStyle = {{ position: "fixed" }}
                          >
                            { groupesClients.map((c) => (
                              <Select.Option key={c.id} value={c.id}>
                                {c.nom}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-3">
                        <Form.Item
                          label="Paiement direct uniquement"
                          name="typeVente"
                        >
                        <Radio.Group>
                          <Radio value={false}>Non</Radio>
                          <Radio value={true}>Oui</Radio>
                        </Radio.Group>
                        </Form.Item>
                      </Col>
                      { modalType !== 1 &&
                      <Row>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Quantité Minimum à la vente"
                            name="qteMinVente"
                            rules={[
                              {
                                required: true,
                                message:"Champ obligatoire !",
                              },
                              {
                                message:"Verifier Quantité Minimum à la vente !",
                                validator:(_,value)=>{
                                  if (isQteMAxGtMin()) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject('Some message here');
                                  }
                                }
                              }
                            ]}
                          >
                            <InputNumber
                              size="large"
                              style={{ width: "100%" }}
                              min={0}
                              max={99999999999}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Quantité Maximum à la vente"
                            name="qteMaxVente"
                            rules={[
                              {
                                required: true,
                                message:"Champ obligatoire !",
                              },
                              {
                                message:"Verifier Quantité Maximum à la vente !",
                                validator:(_,value)=>{
                                  if (isQteMAxGtMin()) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject('Some message here');
                                  }
                                }
                              }
                            ]}
                          >
                            <InputNumber
                              size="large"
                              style={{ width: "100%" }}
                              min={0}
                              max={99999999999}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      }
                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          name="formuleGratuiteId"
                          label="Formule de gratuité"
                        >
                          <Select
                            allowClear
                            size="large"
                            onChange={() => formuleSelectChanged()}
                            dropdownStyle = {{ position: "fixed" }}
                          >
                            { formuleGratuite.map((c) => (
                              <Select.Option key={c.id} value={c.id}>
                                {c.nom}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      { showDates && 
                        <>
                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              label="Date début"
                              name="applicationFormuleDebut"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                                {
                                  message:"Verifier la date Début !",
                                  validator:(_,value)=>{
                                    if (!isDateDebGtFin()) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject('Some message here');
                                    }
                                  }
                                }
                              ]}
                            >
                              <DatePicker  placeholder="" format={""} size="large" className="w-100"/>
                            </Form.Item>
                          </Col>

                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              label="Date fin"
                              name="applicationFormuleFin"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                                {
                                  message:"Verifier la date Fin !",
                                  validator:(_,value)=>{
                                    if (!isDateDebGtFin()) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject('Some message here');
                                    }
                                  }
                                }
                              ]}
                            >
                              <DatePicker placeholder="" format={""} size="large" className="w-100"/>
                              
                            </Form.Item>
                          </Col>
                        </>
                      }
                    </Row>

                  </Tabs.TabPane>

                </Tabs>
              </Form>
            </Modal>
        </Form>
        <AddTvaModalForm
          showModal={tvaModal}
          tvaAdded={(action) => onTvaModalClose(action)}
        ></AddTvaModalForm>
        <AddMarqueModalForm
          showModal={marqueModal}
          marqueAdded={(action) => onMarqueModalClose(action)}
        ></AddMarqueModalForm>
        <AddCategorieModalForm
          showModal={categorieModal}
          categorieAdded={(action) => onCategorieModalClose(action)}
        ></AddCategorieModalForm>
      </Spin>
    );
  };
  export default AddProductModalFormV2;
  