import { Col } from "antd";
import React, { useEffect } from "react";

function FicheTableGain({ gains ,isDarkMode , selectedCurrency}) {
  useEffect(() => {
  }, [gains,selectedCurrency]);
  return (
    <Col span="8" className="px-4">
      <table className={`table ${isDarkMode ? "table-dark" : "" } `}>
        <thead>
          <tr>
            <th scope="col">Gain</th>
            <th scope="col">HT</th>
          </tr>
        </thead>
        <tbody>
          { gains && gains.map((gain,index) => (
              <tr key={index}>
                <th scope="row">{index + 1 }</th>
                <td>{Number(gain.gain_ht).toFixed(3)} {selectedCurrency}</td>
              </tr>
            ))
          }

        </tbody>
      </table>
    </Col>
  );
}
export default FicheTableGain;
