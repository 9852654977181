import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addGroupeClient = ( groupeClient ) => {
  return axios.post(API_URL + "/groupeClient/" , groupeClient ,{ headers: authHeader() });
};

const getGroupeClients = () => {
  return axios.get(API_URL + "/groupeClient/" , { headers: authHeader() });
};
const getActiveGroupeClients = () => {
  return axios.get(API_URL + "/groupeClient/active" , { headers: authHeader() });
};

const updateGroupeClient = (id , groupeClient ) => {
  return axios.put(API_URL + "/groupeClient/" + id , groupeClient, { headers: authHeader() });
};

const deleteGroupeClient = (id) => {
  return axios.delete(API_URL + "/groupeClient/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addGroupeClient,
  getGroupeClients,
  updateGroupeClient,
  deleteGroupeClient,
  getActiveGroupeClients
};
export default exportsMethodes;