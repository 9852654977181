import {
  CheckOutlined, DeleteOutlined, ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card, Col, DatePicker, Empty, Form, InputNumber, message,
  Modal, Row, Spin,
  Table,
  Tabs,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Can from "../../../security/Can";
import BonCommandeVenteService from "../../../services/bonCommandeVente.service";
import PaiementVenteService from "../../../services/paiement.service";
import AppTable from "../../uiHelpers/AppTabe";
import NotAuthorized from "../../uiHelpers/NotAuthorized";

  
  const Accompte = () => {
    const [acompteForm] = Form.useForm()
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [bonCommandesModal, setBonCommandeModal] = useState(false);
    const [commandes , setCommandes] = useState([])
    const [commande,setCommande] = useState({})
    const [acompteModal ,setAcompteModal] = useState(false);
    const history = useHistory();
    const location = useLocation();
    
  
    useEffect(() => {
      loadAcomptes();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };
    const loadAcomptes = async () => {
      setLoading(true);
      const response = await PaiementVenteService.getPaiements();
        if (response.status === 200 || 204) {
            initTableData(response.data);
        } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };

 
    const initTableData = async (data) => {
      const list = []
      for (const element of data) {
        if(((element.moyen_paiement === "" || element.moyen_paiement === null ) && element.etat !== 0) ){
          element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
          element.reference = await getCommandeReference(element.bonCommandeVenteId);
          const nbr = element.id+""
          element.referenceAcompte = "AC-" + nbr.padStart(4, '0');
          list.push(element)
        }
      }
      setDataTable(list);
      setLoading(false);

    };

    const getCommandeReference = async ( bonCommandeId ) => {
      const response = await BonCommandeVenteService.getReference(bonCommandeId);
      return response.data.reference;
    }

    const selectElement = (record) => {
      setCommande(record);
      acompteForm.setFieldsValue({
        montant_ttc : record.total_ttc,
        montant_ht : record.total_ht
      })
      setAcompteModal(true);
    };
  
    const openAcompteAddModal = async () => {
      setLoading(true);
      await BonCommandeVenteService.getBonCommandesWithoutActiveFacture((response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        const list = [];
        for (const element of data) {
          if( element.paiement_status !== 1 ){
            element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
            list.push(element);
          }
        }
        setCommandes(list);
      } else message.error("Une erreur est survenue ! ");
      });
      setLoading(false);
      setBonCommandeModal(true);
    };

    const deletePaiement = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer ce paiement ?",
        okText: "Oui",
        onOk: () => deleteItem(record),
        cancelText: "Non",
      });
    };
    const deleteItem = async (item) => {
      const response = await PaiementVenteService.deleteAcompte(item.id);
      if (response.status === 204 || 200) {
        await loadAcomptes();
        setAcompteModal(false);
        message.info("Suppression avec succès");
      } else message.error("Une erreur est survenue ! ");
    };

    const accompteCols = [
      {
        title: "Référence Acompte",
        dataIndex: "referenceAcompte",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
      },
      {
        title: "Référence Bon de commande",
        dataIndex: "reference",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
      },
      {
        title: "Date",
        dataIndex: "showDate",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: "Montant Acompte",
        dataIndex: "montant",
        render: (text, record) => (
          <b>{Number(record.montant).toFixed(3)} TND</b>
        ),
        sorter: (a, b) => a.montant_total - b.montant_total,
      },{
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              <Button
                onClick={() => deletePaiement(record)}
                className="mx-1"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];

    const bonCommandeCols = [
        {
          title: "Référence Bon de commande",
          dataIndex: "reference",
          sorter: (a, b) => a.nom.localeCompare(b.nom),
        },{
          title: "Date",
          dataIndex: "showDate",
          sorter: (a, b) => a.description.localeCompare(b.description),
        },{
          title: "Montant HT",
          dataIndex: "total_ht",
          render: (text, record) => (
            <b>{Number(record.total_ht).toFixed(3)} {record.devise}</b>
          ),
          sorter: (a, b) => a.montant_total - b.montant_total,
        },{
          title: "Montant TTC",
          dataIndex: "montant_total",
          render: (text, record) => (
            <b>{Number(record.montant_total).toFixed(3)} {record.devise}</b>
          ),
          sorter: (a, b) => a.montant_total - b.montant_total,
        },{
          title: "Action",
          key: "action",
          render: (text, record) => (
            <div>
              <Tooltip title="Choisir">
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => selectElement(record)}
                  icon={<CheckOutlined />}
                />
              </Tooltip>
            </div>
          ),
        },
    ];


    const addAcompte = async (values) => {
      setLoading(true);
      const acompte = acompteForm.getFieldsValue();
      const fullyPaied = Number(acompte.acompte_pourcentage) === 100 ? 1 : 2;  
      acompte.fullyPaied = fullyPaied;
      acompte.bonCommandeVenteId = commande.id;
      const response = await PaiementVenteService.addAcompte(acompte);
      if( response.status === 200 ){
        setAcompteModal(false);
        loadAcomptes();
        acompteForm.resetFields();
        message.info("Acompte ajouté avec succès" );
      } else message.error("Une erreur est survenue ! ");   
      setLoading(false)
    }
  

    const acomptePourcentageChanged = () => {
      const acompte = acompteForm.getFieldsValue();
      const montant = (Number(acompte.acompte_pourcentage) * Number(acompte.montant_ttc)  / 100 ).toFixed(3);
      acompteForm.setFieldsValue({montant})
    }

    const acompteMontantChanged = () => {
      const acompte = acompteForm.getFieldsValue();
      const taxe = acompte.montant_ttc / acompte.montant_ht;
      const m_pourcentage_ht =  ( acompte.montant / taxe ).toFixed(3);
      const acompte_pourcentage = Number( (100 * m_pourcentage_ht) / acompte.montant_ht ).toFixed(2);
      acompteForm.setFieldsValue({acompte_pourcentage})
    }
    const locale = {
      emptyText: (
        <Empty description={"Le tableau est vide pour le moment "}></Empty>
      ),
    };

    return (
      <>
      {Can("14_8") ? (
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Liste des acomptes"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              onClick={() => openAcompteAddModal()}
            >
              Ajouter Un Acompte
            </Button>
          }
        >
            <Table
             expandable={{
              expandedRowRender: record => <p className="px-1"><b> Description : </b>{record.description }</p>,
              rowExpandable: record => record.description !== null
              }}
              showSorterTooltip={false}
              rowKey={(record) => record.id}
              locale={locale}
              columns={accompteCols}
              dataSource={dataTable}
              pagination={{ position: ["bottomCenter"], pageSize: 5 }}
            />

        </Card>
        <Modal
          visible={bonCommandesModal}
          getContainer={false}
          centered
          footer={null}
          onCancel={() => setBonCommandeModal(false)}
          title={"Acompte"}
          width={950}
        >
          <Spin spinning={isLoading} size="large">
            <Tabs defaultActiveKey="1" >
              <Tabs.TabPane tab="Bons des commandes" key="1">
                <AppTable data={commandes} columns={bonCommandeCols} />
              </Tabs.TabPane>
            </Tabs>
          </Spin>
        </Modal>
        <Modal
          visible={acompteModal}
          getContainer={false}
          centered
          onOk={acompteForm.submit}
          onCancel={() => setAcompteModal(false)}
          title={"Acompte"}
          width={1100}
        >
          <Form
              layout="vertical"
              form={acompteForm}
              onFinish={addAcompte}
            >
              <Row>
                <Col span={6} className="px-2">
                  <Form.Item
                    label="Acompte en montant TTC"
                    name="montant"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire !",
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={()=> acompteMontantChanged()}
                      size="large"
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} className="px-2">
                  <Form.Item
                    label="Acompte en pourcentage"
                    name="acompte_pourcentage"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire !",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled
                      size="large"
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      onChange={()=> acomptePourcentageChanged()}
                    />
                  </Form.Item>
                </Col>

                <Col span={6} className="px-2">
                  <Form.Item
                    label="Montant HT du bon de commande"
                    name="montant_ht"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire !",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled
                      size="large"
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} className="px-2">
                  <Form.Item
                    label="Montant TTC du bon de commande"
                    name="montant_ttc"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire !",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled
                      size="large"
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Date"
                    name="date"
                    rules={[
                      {
                        required: true,
                        message:"Champ obligatoire !",
                      },
                    ]}
                  >
                    <DatePicker placeholder=" " size="large" className="w-100"></DatePicker>
                  </Form.Item>
                </Col>

                <Col span={24} className="px-2">
                  <Form.Item
                    label="Note"
                    name="description"
                    rules={[
                      {
                        message:
                          "Champ obligatoire avec maximum de caractère 255 !",
                        max: 254,
                      },
                    ]}
                  >
                    <TextArea rows={2} type="text" maxLength={255} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
        </Modal>
      </Spin>
            ) : (
              <NotAuthorized></NotAuthorized>
            )}
          </>
    );
  };
  export default Accompte;
  