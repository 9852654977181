import { Button, Card, Col, Form, Image, Input, message, Row, Spin } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import pageCover from "../../assets/images/invoiceback.jpg";
import logo from "../../assets/images/logo.png";
import "../../assets/purple-antd.css";
import AppFooter from "../uiHelpers/AppFooter";


function Inscription() {
  const [EntrepriseForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => { checkUrl()  }, []);

  const checkUrl = () => {
    // const url = window.location.href;
    // const domaine = url.split("/");
    // if( !domaine[2].includes("lookup.dndserv.cc"))
    // history.push("/login");
  }

  const createAccount = async ( data ) => {
    // setLoading(true);
    // await axios.post("http://51.210.180.156:5256/createNewLookupInstance",{ name : data.nom })
    // .then( async data => {
    //   if(data.result === "OK"){
    //     await axios.post("http://51.210.180.156:"+data.port+"/auth/" , data )
    //     .then( data => {
    //       creationSuccess();
    //       setLoading(false);
    //       setTimeout( () => {
    //         window.location.assign("http://"+data.domaine);
    //       },4000)
    //     }).catch( err => {
    //       console.log(err);
    //       message.error("Erreur lors de la creation du compte administrateur sur le sous domaine : " + data.domaine)
    //       setLoading(false);
    //     })
    //   }
    // }).catch( err => {
    //   console.log(err);
    //   message.error("Erreur lors de la creation du sous domaine : " + data.domaine );
    //   setLoading(false);
    // })
  };

  const creationSuccess = () => {
    message.info("Inscription avec succès");
    history.push("/login");
  }

  return (
    <Spin spinning={isLoading} size="large" tip={<h4>Cette opération va durer quelques minutes ...</h4>}>

    <Layout style={{ minHeight: "100vh" , backgroundImage : pageCover }}>
      <Content style={{ margin: "0 16px" }}>
        <Row>
          <Col span={16} offset={4} className="mt-5 ">
            <Card>
              <Row>                   
                <Col span={11}>
                  <div className="text-center my-2">
                    <Image preview={false}  width={"50%"} src={logo} />
                  </div>
                  <Form
                    name="EntrepriseForm"
                    initialValues={{ remember: true }}
                    onFinish={createAccount}
                    autoComplete="On"
                    layout="vertical"
                  >
                    <Form.Item
                      label="Nom de votre Entreprise"
                      name="nom"
                      rules={[
                        { required: true, message: "Champ obligatoire!" },
                      ]}
                    >
                      <Input type="text" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'Format invalid!',
                        },
                        {
                          required: true,
                          message: 'champ obligatoire !',
                        },
                      ]}
                    >
                      <Input type="text" size="large" />
                    </Form.Item>

                    <Form.Item
                      label="Mot de passe"
                      name="password"
                      rules={[
                        { required: true, message: "Champ obligatoire!" },
                      ]}
                    >
                      <Input.Password size="large" />
                    </Form.Item>        

                    <Form.Item
                      label="Téléphone "
                      name="telephone"
                      rules={[
                        { required: true, message: "Champ obligatoire!" },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>

                    <div className="text-center">
                      <Form.Item>
                        <Button disabled={isLoading} type="primary" htmlType="submit">
                          Connexion
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </Col>
                <Col span={12} offset={1}>
                    <div className="image-cover2">
                        <div className="image-overlay2"></div>
                    </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <AppFooter></AppFooter>
    </Layout>
    </Spin>
  );
}
export default Inscription;
