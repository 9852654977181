import {
  CheckOutlined, DeleteOutlined, PlusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col, Divider, Form, Input, InputNumber, message, Modal, Row,
  Spin,
  Tooltip
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Can from "../../../security/Can";
import ClientService from "../../../services/client.service";
import EntrepriseService from "../../../services/entreprise.service";
import FactureService from "../../../services/factureVente.service";
import ProduitService from "../../../services/produit.service";
import TaxeService from "../../../services/taxe.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableGain from "../../uiHelpers/Fiches/FicheTableGain";
import FicheTableTotauxFactureVente from "../../uiHelpers/Fiches/FicheTableTotauxFactureVente";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import AddProductModalForm from "../General/AddProductModalForm";
import writtenNumber from '/node_modules/written-number/lib/index';
import currencys from '../../../helpers/currency';
import TableWithFiltres from "../../uiHelpers/TableFilters";
import Text from "antd/lib/typography/Text";

writtenNumber.defaults.lang = 'fr';
  
  function FactureSimple() {
    const [productsForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [fournisseur, setFournisseur] = useState(null);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(null);
    const [entreprise, setEntreprise] = useState({});
    const [visualisationModal, setVisualisationModal] = useState(false);
    const [productsModal, setProductsModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isDarkMode, setDarkMode] = useState(false);
    const [productList, setProductList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [appliedTaxes, setListAppliedTaxes] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [gains, setGain] = useState([]);
    const [productModal , setProductModal] = useState(false);
    const [fodec , setFodec] = useState(false);
    const [writtenTotal,setWrittenTotal] = useState("");
    const [selectedCurrency,setSelectedCurrency] = useState("");

    const history = useHistory();
  
    const location = useLocation();
  
    const [prixTotal, setPrixTotal] = useState({
      montant_total: 0,
      total_ttc: 0,
      total_ht: 0,
      fodec: 0,
      tva: 0,
      totalTimbreFiscal: "1.000",
    });
  
    const isVente = true;
  
    useEffect(() => {
      loadProduits();
      loadClients();
      loadEntreprise();
      getThemeMode();
      setUpdate();
      return () => {
        clearStates();
      };
    }, []);

    const loadTaxeFodec = async () => {
      const response = await TaxeService.checkTaxeFodec();
      setFodec(response.data);
    }
  
    const setUpdate = async () => {
      if( location.state?.id ){
        const id = location.state?.id;
        setLoading(true);
        await FactureService.getFacture( id , ( response )=> {
          if (response.status === 200 || 204) {
            const activeFodec = response.data.fodec > 0 ? true : false; 
            setFodec(activeFodec)
            const clientId = response.data.produit_facture_ventes[0].clientId;
            const productList = {...response.data }
            productList.date = moment(productList.date);
            productList.client = clientId;
            productsForm.setFieldsValue(productList);
            loadProduits();
            calculeTotalTaxes(activeFodec);
            setSelectedCurrency(response.data.devise);
            const ids = []
            for (const element of productList.produit_facture_ventes) ids.push(element.produitId);
            setSelectedIds(ids);
            setUpdateId(id);
            clientChanged();
            setEditMode(true);
          }else message.error("Une erreur est survenue ! ");
          setLoading(false);
        });
      }else{
        await loadTaxeFodec()
        generateReference();
      }
    }
    const generateReference = async () => {
      await FactureService.getLast( ( response )=> {
        if (response.status === 200 || 204) {
          if(response.data.length === 0){
            var nbr  = "1";
            var reference = "FV-" + nbr.padStart(4, '0');
          }else{
            const lastRef = response.data.reference;
            const refNbr = (Number(lastRef.substring(3, 7)) + 1 ) + "";
            var reference = "FV-" + refNbr.padStart(4, '0');
          }
          productsForm.setFieldsValue({reference: reference ,date : moment(new Date()) } )
        }else message.error("Une erreur est survenue ! ");
        setLoading(false);
      });
    }
  
    const loadProduits = async () => {
      setLoading(true);
      const response = await ProduitService.getProduitsMainStore();
      if (response.status === 200 || 204) initTableData(response.data);
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    const getProducts = async () => {
      const response = await ProduitService.getProduitsMainStore();
      if (response.status === 200 || 204) return response.data;
      else message.error("Une erreur est survenue ! ");
    };
    
    const initTableData = (produits) => {
      produits = formatteProductsToDataTable(produits);
      setProductList(produits);
    };
  
    const formatteProductsToDataTable = (produits) => {
      for (const produit of produits) {
        produit.showType = produit.type === 2 ? "Produit avec embalage" : "Produit";
        produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
        produit.htAchat = produit.produit_achats[0]?.prixHT;
        produit.pqte = !produit.type ? produit.stocks[0]?.quantite : "";
        produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
        produit.showFournisseur = produit.produit_achats[0]?.fournisseur?.raison_sociale ?? "Produit proprietere";
        produit.ttcVente = produit.produit_ventes[0].prixTTC;
        produit.htVente = produit.produit_ventes[0].prixHT;
      }
      return produits;
    };
  
    const selectProduct = (produit) => {
      console.log( produit );

      setSelectedIds((prev) => [...prev, produit.id]);
      formatteProduct(produit);
    };
  
    const removeElement = (produitId) => {
      const list = selectedIds;
      setSelectedIds(list.filter((idP) => idP !== produitId));
    };
  
    const isSelected = (id) => {
      return selectedIds.includes(id);
    };
  
    const formatteProduct = (produit) => {
      
      const qte = produit?.quantite ?? 1;
      const quantiteGratuite = produit?.quantiteGratuite ?? 0;
      const quantiteTotal = produit?.quantiteTotal ?? 1;
      const prixUniteHt = produit?.produit_ventes[0]?.prixHT;
      const prixUniteTTC = produit?.produit_ventes[0]?.prixTTC;
      const tva = produit?.produit_ventes[0]?.tva;
      const ht = produit?.produit_ventes[0]?.total_prix_HT ?? Number(prixUniteHt) * Number(qte);
      const ttc = produit?.produit_ventes[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);
      const remise = produit?.remise ?? 0;
      const product = {
        produitId: produit?.id,
        libelle: produit?.libelle,
        quantite: Number(qte),
        quantiteGratuite: Number(quantiteGratuite),
        quantiteTotal: Number(quantiteTotal),
        prixHT: Number(prixUniteHt),
        prixTTC: Number(prixUniteTTC),
        tva: Number(tva),
        remise: Number(remise),
        total_prix_HT: Number(ht).toFixed(3),
        total_prix_TTC: Number(ttc).toFixed(3),
      };
      const productList = productsForm.getFieldValue("produit_facture_ventes")
        ? productsForm.getFieldValue("produit_facture_ventes")
        : [];
      productList.push(product);
      productsForm.setFieldsValue({ produit_facture_ventes: productList });
      calculeTotalTaxes();
    };
  
    const getThemeMode = () => {
      const theme = localStorage.getItem("COLOR_THEME");
      const darkMode = theme === "DARK" ? true : false;
      setDarkMode(darkMode);
    };
  
    const loadClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        console.log(response.data);
        setClients(response.data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    const getClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        return response.data;
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    
  
    const loadEntreprise = async () => {
      setLoading(true);
      const response = await EntrepriseService.getEntreprise();
      if (response.status === 200 || 204) {
        setEntreprise(response.data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
  
    const clearStates = () => {
      setEditMode(false);
      setUpdateId(null);
    };
  
    const handleCancel = () => {
      setProductsModal(false);
    };
  
  
    const checkIfIsFormuleGratuite = ( produit ) =>  {
      console.log("etat",produit)
      console.log( produit?.formule_gratuite?.etat === 1 )
      console.log(
        moment(new Date()).isBetween( 
          moment( produit?.applicationFormuleDebut ),
          moment( produit?.applicationFormuleFin )
        )
      )

      if( 
        produit?.formule_gratuite?.etat === 1 && 
        moment(new Date()).isBetween( 
          moment( produit?.applicationFormuleDebut ),
          moment( produit?.applicationFormuleFin )
        )
      ) return true;
      else return false;
    }


    const priceChanged = (index) => {
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const product = products[index];
      console.log(product);
      const dbProduct = productList.find( (e)=> e.libelle === product.libelle );
      console.log(dbProduct);
      const applicateFormule = checkIfIsFormuleGratuite(dbProduct);


      
      const quantite = product.quantite;

      console.log(Math.floor( quantite / dbProduct?.formule_gratuite?.nombreAchete));
      console.log(Math.floor( quantite / dbProduct?.formule_gratuite?.nombreAchete));
      
      
      const quantiteGratuite =  applicateFormule ? 
          Math.floor( quantite / dbProduct.formule_gratuite?.nombreAchete) * dbProduct.formule_gratuite?.nombreGratuit :
          product.quantiteGratuite;

      
      const taxe = product.tva;

      const prixHT = Number(product.prixHT - product.prixHT * (product.remise / 100)).toFixed(3);
      const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(3);
      const total_ht = Number(prixHT * quantite).toFixed(3);
      const total_ttc = Number(prixTTC * quantite).toFixed(3);
  
      products[index].total_prix_TTC = total_ttc;
      products[index].total_prix_HT = total_ht;
      products[index].quantiteGratuite = quantiteGratuite;
      products[index].quantiteTotal = quantiteGratuite + quantite;

      productsForm.setFieldsValue({ products });
  
      calculeTotalTaxes();
      calculeQte();
  
    };

    const calculeQte = () => {
      console.log("called");
    }
  
    const calculeTotalTaxes = async (activeFodec) => {
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const list = [];
      const listGain = [];
      const isFodecActive = activeFodec ? activeFodec : fodec
      for (const product of products) {
        if (product.tva > 0.1) {
          const base = isFodecActive ? 
            Number(product.total_prix_HT) + Number(product.total_prix_HT * 1) / 100 :
            Number(product.total_prix_HT);
          const montantTaxes = isFodecActive ?
            ((Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100)).toFixed(3) :
            (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(3);
          const taxe = {
            tvaPorucentage: product.tva,
            montantTaxes: montantTaxes,
            base: base,
          };
          list.push(taxe);
        }
        const listProd = productList.length ? productList : await getProducts(); 
        const prod = listProd.find( prod => prod.id === product.produitId);
        const gain_ht = ( product.total_prix_HT * product.quantite  - (prod?.produit_achats[0]?.prixHT * product.quantite)   ).toFixed(3);
        const gain_ttc = (product.prixTTC - prod?.produit_achats[0]?.prixTTC ) * product.quantite;
        const gain = {gain_ht , gain_ttc};
        listGain.push(gain);
      }
      setGain(listGain)
  
      setListAppliedTaxes(list);
      calculateTotal(isFodecActive);
    };
  
  
    const clientChanged = async () => {
      const clientId = productsForm.getFieldValue("client");
      const clientList = clients.length ? clients : await getClients();
      const client =  clientList.find((client) => client.id === clientId);

      console.log( client );
      // const products = productList.filter( (e) => getGroupProducts( client.groupeClientId , e?.produit_groupes ?? [] ) )
      setProductList(productList)
      setClient( client );
    };

    const getGroupProducts = ( groupeId , productGroups ) => {
      return productGroups.some( (e) => e.groupeClientId === groupeId)
    }

    const calculateTotal = (activeFodec) => {
      const isFodecActive = activeFodec ? activeFodec : fodec
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;
      let total_ht = 0;
      let tva = 0;
      let totalTimbreFiscal = 1;
      let total_ttc = 0;
  
      for (const product of products) {
        const montantTaxes = isFodecActive ? 
          (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) :
          (Number(product.total_prix_HT) ) * (product.tva / 100) ;
        total_ht += Number(product.total_prix_HT);
        tva += montantTaxes;
      }
      total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100))
  
      let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
      total_ttc += Number(total_ht) + Number(tva) + Number(fodecMontant);
      let montant_total = (Number(total_ttc) + Number(totalTimbreFiscal)).toFixed(3);
      const total = { montant_total, total_ttc, total_ht, fodec: fodecMontant, tva, totalTimbreFiscal };
      setPrixTotal(total);

      const final = montant_total.toString().split(".");
      const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
      setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);
  
    };
  
    const getProductId = (index, name) => {
      return productsForm.getFieldValue("produit_facture_ventes")[index]?.produitId;
    };
  
    const addFactureVente = async () => {
      setLoading(true);
      const product = productsForm.getFieldsValue();
      for (const element of product.produit_facture_ventes) {
        element.clientId = productsForm.getFieldValue("client");
      }
      const facutre = {...product , ...prixTotal }
      facutre.etat = 0;
      facutre.remise_global =  facutre.remise_global ? facutre.remise_global : 0;
      const response = editMode ? 
        await FactureService.updateFacture(updateId,facutre) 
        : await FactureService.addFacture(facutre);
      if (response.status === 200 || 204) {
        if(editMode) message.info("Mise à jour avec succès");
        else message.info("Facture ajouté avec succès");
        history.push("/s/factures");
  
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    }
  
    const ProduitColumns = [
      {
        title: "Type",
        dataIndex: "showType",
        key: "1",
        sorter: (a, b) => a.type.localeCompare(b.type),
      },
      {
        title: "Libelle",
        dataIndex: "libelle",
        sorter: (a, b) => a.showNom.localeCompare(b.showNom),
      },
      {
        title: "Référence Int",
        dataIndex: "reference_interne",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: "Fournisseur",
        dataIndex: "showFournisseur",
        sorter: (a, b) => a.valeur - b.valeur,
      },
      {
        title: "Quantité",
        dataIndex: "pqte",
        sorter: (a, b) => a.pqte - b.pqte,
      },
      {
        title: "Achat TTC",
        dataIndex: "ttcAchat",
        sorter: (a, b) => a.valeur - b.valeur,
      },
      {
        title: "Vente TTC",
        dataIndex: "ttcVente",
        sorter: (a, b) => a.valeur - b.valeur,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Choisir">
              <Button
                disabled={isSelected(record.id)}
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => selectProduct(record)}
                icon={<CheckOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
    const reloadClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        setClients(response.data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    const openProductModal = () => {
      setProductModal(true);
    };
    const onCloseProductModal = async (action) => {
      if (action) {
        const fournisseurId = productsForm.getFieldValue("fournisseur");
        await loadProduits(fournisseurId);
      }
      setProductModal(false);
    };
  
    const changeCurrency = () => {
      const currency = productsForm.getFieldValue("devise");
      setSelectedCurrency(currency);
    }
    const disableForm = () => {
      return selectedIds.length === 0;
    }

    const getLibelle = (index) => {
      return productsForm.getFieldValue("produit_facture_ventes")[index]?.libelle;
    };

    const getProduitMin = ( index ) =>{
      const libelle = productsForm.getFieldValue("produit_facture_ventes")[index]?.libelle;
      return productList.find( (e) => e.libelle = libelle ).qteMinVente;

    } 

    const getProduitMax = ( index ) =>{
      const libelle = productsForm.getFieldValue("produit_facture_ventes")[index]?.libelle;
      return productList.find( (e) => e.libelle = libelle ).qteMaxVente;
    } 
  
    return (
      <>
      {Can("19_2") ? (
        <Spin  className="px-5" spinning={isLoading} size="large" >
          <Card className="w-100 card-shadow">
            <Form layout="vertical" initialValues={{ devise: "TND", }} form={productsForm} onFinish={addFactureVente}>
              <Row>
                <FicheHeader
                  moduleName={"Facture "}
                  showClients={isVente}
                  entreprise={entreprise}
                  clients={clients}
                  fournisseurs={fournisseurs}
                  selectedClient={() => clientChanged()}
                  reloadClients={() => reloadClients()}

                ></FicheHeader>
              </Row>
              <Row>
                {isVente ? (
                  <Col span={8} className="px-2 pt-3">
                    <FicheClient client={client}></FicheClient>
                  </Col>
                ) : (
                  <Col span={8} className="px-2 pt-3">
                    <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
                  </Col>
                )}
                <Col span={8} offset={8} className="px-2 pt-3">
                  <FicheDetails
                    currencyChanged={ ()=>  changeCurrency()}
                  ></FicheDetails>
                </Col>
              </Row>
              <Row>
                  <Col span="24" className="px-4">
                    <table
                      className={` mb-5 table ${
                        isDarkMode ? "table-dark" : ""
                      } `}
                    >
                      <thead className="pl-2">
                        <Row>
                          <Col span={1}>
                            <b>#</b>
                          </Col>
                          <Col span={6}>
                            <b>Libelle</b>
                          </Col>


                          <Col span={2}>
                            <b>Quantité</b>
                          </Col>

                          <Col span={2}>
                            <Tooltip title="Quantité Gratuite">
                              <b className="pl-3">QG</b>
                            </Tooltip>
                          </Col>

                          <Col span={1}>
                            <Tooltip title="Quantité Total">
                              <b>QT</b>
                            </Tooltip>
                          </Col>

                          <Col span={2}>
                            <Tooltip title="Prix Unitaire HT">
                              <b>Prix HT</b>
                            </Tooltip>
                          </Col>

                          <Col span={2}>
                            <b>TVA</b>
                          </Col>

                          <Col span={2}>
                            <b>Remise</b>
                          </Col>

                          <Col span={2}>
                            <b>Total HT</b>
                          </Col>

                          <Col span={2}>
                            <b>Total TTC</b>
                          </Col>

                          <Col className="px-3" span={2}>
                            <Tooltip title="Ajouter un produit">
                              <Button
                                shape="circle"
                                disabled={!client}
                                icon={<PlusCircleOutlined />}
                                onClick={() => setProductsModal(true)}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      </thead>
                      <tbody>
                        <Form.List name="produit_facture_ventes" label="">
                          {(fields, { add, remove }) => (
                            <>
                              <Row className="pb-1 mb-1 ">
                                {fields.map(
                                  ({ key, name, ...restField }, index) => (
                                    <>
                                      <Col className="mb-0 pb-0" span={1}>
                                        {index + 1}
                                      </Col>
                                      <Col className="mb-0 pb-0" span={6}>
                                        <Text className="mb-0 pb-0">
                                          {getLibelle(index)}
                                        </Text>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "libelle"]}
                                          style={{ height: 0 }}
                                        >
                                          <Input
                                            type="hidden"
                                            bordered={false}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantite"]}
                                        >
                                          <InputNumber
                                            style={{ width: 80 }}
                                            onChange={() => priceChanged(index)}
                                            className="w-125px"
                                            min={getProduitMin(index)}
                                            max={getProduitMax(index)}

                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantiteGratuite"]}
                                        >
                                          <InputNumber
                                            style={{ width: 60 }}
                                            className="w-125px"
                                            min={0}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={1}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantiteTotal"]}
                                        >
                                          <InputNumber
                                            className={`${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 50 }}
                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "prixHT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "tva"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            style={{ width: 60 }}
                                            min={0}
                                            max={100}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) =>
                                              value.replace("%", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "remise"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            min={0}
                                            max={100}
                                            style={{ width: 60 }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) =>
                                              value.replace("%", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_HT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={` ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_TTC"]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0 px-3" span={2}>
                                        <Tooltip title="Supprimer le produit">
                                          <Button
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                              removeElement(
                                                getProductId(index)
                                              );
                                              remove(name);
                                              calculeTotalTaxes();
                                            }}
                                          />
                                        </Tooltip>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </tbody>
                    </table>
                  </Col>
                  <Col span={8} offset={16} className="px-4">
                    <table
                      className={`table ${isDarkMode ? "table-dark" : ""} `}
                    >
                      <thead>
                        <tr>
                          <th scope="col" className="pb-3">
                            Remise :{" "}
                          </th>
                          <th scope="col">
                            <Form.Item
                              name="remise_global"
                              className="my-0 py-1"
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                disabled
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" className="pb-3">
                            Acompte :
                          </th>
                          <th scope="col">
                            <Form.Item
                              name="acompte_pourcentage"
                              className="my-0 py-1"
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                disabled
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                  <FicheTableGain
                   selectedCurrency={selectedCurrency}
                    gains={gains}
                    isDarkMode={isDarkMode}
                  ></FicheTableGain>
                  <FicheTableTva
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    appliedTaxes={appliedTaxes}
                  ></FicheTableTva>
                  <FicheTableTotauxFactureVente
                    fodec={fodec}
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    prixTotal={prixTotal}
                  ></FicheTableTotauxFactureVente>
                </Row>
              <Divider className="text-capitalize">{writtenTotal}</Divider>

    
              <FicheFooter entreprise={entreprise}></FicheFooter>
    
              <Row className="d-flex flex-row-reverse pt-5 pb-2">
                <Button 
                  className="mx-2" 
                  type="primary"
                  onClick={productsForm.submit}
                  disabled={disableForm()}

                >
                  Enregistrer
                </Button>
                
              </Row>
            </Form>
          </Card>
    
          <Modal
            visible={productsModal}
            getContainer={false}
            centered
            footer={null}
            onCancel={handleCancel}
            title={
            <div className="d-flex justify-content-between px-4">
              <div> Liste des Produits </div>
                <Button
                  className="mr-5 pr-5"
                  shape="round"
                  type="primary"
                  onClick={() => openProductModal()}
                > Ajouter un produit </Button>
            </div>}
            width={1000}
          >
            <Spin spinning={isLoading} size="large">
              <TableWithFiltres data={productList} columns={ProduitColumns} />
            </Spin>
          </Modal>

        </Spin>      
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
    );
  }
  
  export default FactureSimple;
  