import { Button, Card, Checkbox, Col, Form, Image, Input, message, Row } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import dndLogo from "../../assets/images/app3.png";
import EntrepriseService from "../../services/entreprise.service";
import AppFooter from "../uiHelpers/AppFooter";

function Login({onLoginSuccess}) {
  const [EntrepriseForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [showInscriptionButton , setInscriptionButton] = useState(true);
  const history = useHistory();


  useEffect(() => { checkUrl() }, []);

  const checkUrl = () => {
    const url = window.location.href;
    const domaine = url.split("/");
    if( domaine[2].includes("lookup.dndserv.cc"))
      setInscriptionButton(true);
  }

  const login = async ( data ) => {
    setLoading(true);
    const response = await EntrepriseService.loginEntreprise( data )
    if (response.status === 200 || 204) responseSuccess(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const goToInscription = () => {
    history.push("/inscription");

  }
  const responseSuccess = (data) => {
    if( data.login ==="FAILED" ){
      message.error(data.DESC);
    }else{
      message.info(" Bienvenu ");
      onLoginSuccess(data);
      history.push("/");
    }
  }
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ margin: "0 16px" }}>
        <Row>
          <Col span={16} offset={4} className="mt-5 pt-5">
            <Card>
              <Row>
                <Col span="11" >
                  <div className="image-cover">
                    <div className="image-overlay"></div>
                  </div>
                </Col>
                <Col span="11" offset={1}>
                  <div className="text-center my-4">
                    <Image preview={false}  width={"50%"} src={dndLogo} />
                  </div>
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={login}
                    autoComplete="On"
                    layout="vertical"
                  >
                    <Form.Item
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'Format invalid!',
                        },
                        {
                          required: true,
                          message: 'champ obligatoire !',
                        },
                      ]}
                    >
                      <Input type="text" size="large" />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        { required: true, message: "Champ obligatoire!" },
                      ]}
                    >
                      <Input.Password size="large" />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox>Rester connecter</Checkbox>
                    </Form.Item>

                    <div className="text-center">
                      <Form.Item>
                        {/* { showInscriptionButton &&  <Button onClick={()=> goToInscription()} className="mx-2">Inscription</Button> } */}
                        <Button type="primary" htmlType="submit" className="mx-2"> Connexion </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <AppFooter></AppFooter>
    </Layout>
  );
}
export default Login;
