import {
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Spin
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import TvaService from "../../../../services/tva.service";

const AddTvaModalForm = ({showModal, tvaAdded}) => {
  const [tvaForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {}, [showModal]);

  const handleCancel = () => {
    closingModal();
    tvaAdded(false);
  };
  const closingModal = () => {
    setLoading(false);
    tvaForm.resetFields();
  };
  const addTva = async (values) => {
    setLoading(true);
    var data = values;
    data.etat = 1;
    data.type = 1;
    const response = await TvaService.addTva(data);
    if (response.status === 200 || 204) {
      message.info("Tva ajouté avec succès");
    } else message.error("Une erreur est survenue ! ");
    closingModal();
    tvaAdded(true);
  };

  return (
      <Form layout="vertical" form={tvaForm} onFinish={addTva}>
        <Modal
          visible={showModal}
          onOk={tvaForm.submit}
          onCancel={handleCancel}
          getContainer={false}
          okText="Enregistrer"
          cancelText="Annuler"
          centered
          width={600}
          title={"Ajouter une TVA"}
        >
          <Row>
            <Col span={12} className="px-2">
              <Form.Item
                label="Nom de la TVA "
                name="titre"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input size="large" type="text" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12} className="px-2">
              <Form.Item
                label="Valeur"
                name="valeur"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire  !",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={0}
                  max={99}
                />
              </Form.Item>
            </Col>

            <Col span={24} className="px-2">
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    message:
                      "Champ obligatoire avec maximum de caractère 255 !",
                    max: 254,
                  },
                ]}
              >
                <TextArea rows={4} type="text" maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
  );
};
export default AddTvaModalForm;
