const banks = [
    { id: 0, code_fr: "Banque Tunisien International" },
    { id: 1, code_fr: "Banque Centrale De Tunisie" },
    { id: 2, code_fr: "Poste Tunisienne" },
    { id: 3, code_fr: "Union Internale de Banque" },
    { id: 4, code_fr: "Union Bancaire De Commerce Et D'Industrie" },
    { id: 5, code_fr: "Qatar National Bank - Tunis" },
    { id: 6, code_fr: "Citi Bank" },
    { id: 7, code_fr: "Banque Franco Tunisienne" },
    { id: 8, code_fr: "Banque Tuniso Koweitienne" },
    { id: 9, code_fr: "Banque De Tunisie" },
    { id: 10, code_fr: "Banque International Arabe de Tunisie" },
    { id: 11, code_fr: "Arab Banking Corporation" },
    { id: 12, code_fr: "Arab Tunisian Bank" },
    { id: 13, code_fr: "Attijari Bank" },
    { id: 14, code_fr: "Amen Bank" },
    { id: 15, code_fr: "Al Wifak Internation Bank" },
    { id: 16, code_fr: "Al Baraka Bank" },
    { id: 17, code_fr: "Banque Zitouna" },
    { id: 18, code_fr: "Banque Tuniso-Libyenne" },
    { id: 19, code_fr: "Banque De Tunisie Et Des Emirats" },
    { id: 20, code_fr: "Banque Tunisienne De Solidarité" },
    { id: 21, code_fr: "Banque De Financement Des Petites Et Moyennes Entreprise " },
    { id: 22, code_fr: "Banque DE L'Habitat" },
    { id: 23, code_fr: "Banque National Agricole" },
    { id: 24, code_fr: "Société Tunisienne De Banque" },
  ];
  
  export default banks;
  