import { Table, Input, Button, Space, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';



export default class TableWithFiltres extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    columns : []
  };

  componentDidMount(){
    const cols = this.props.columns;
    const list = [];
    for (const col of cols) {
        if(col.title !== "Action" && col.title !== "Etat" && col.title !== "Etat Paiement" && col.title !== "Type" ){
            const newCol = {...col , ...this.getColumnSearchProps(col.dataIndex) }
            list.push(newCol);
        }else{
            list.push(col);
        }
    }
    this.setState({columns : list })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, width:"300px" }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className='d-flex flex-row-reverse'>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            className='px-3'
        >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(selectedKeys, confirm, dataIndex,clearFilters)} className='px-3 mx-2'>
            Annuler
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>{
      return record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''
    }
      ,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (selectedKeys, confirm, dataIndex,clearFilters) => {
    clearFilters();
    confirm();
    this.setState({
      searchText: '',
      searchedColumn: dataIndex,
    });
  };

  render() {
        const locale = {
            emptyText: (
                <Empty description={"Le tableau est vide pour le moment "}></Empty>
            ),
        };
        return <Table 
                  rowKey={(record) => record.id}
                  locale={locale} 
                  pagination={{ position: ["bottomCenter"], defaultPageSize: this.props?.size ?? 5 }}
                  showSorterTooltip={false}
                  columns={this.state.columns}
                  dataSource={this.props.data} 
                />;
  }
}
