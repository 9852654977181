import {
    MinusCircleOutlined
} from "@ant-design/icons";
import {
    Button, Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select, Spin, Tabs
} from "antd";
import React, { useEffect, useState } from "react";
import country from "../../../helpers/country";
import FournisseurService from "../../../services/fournisseur.service";

const AddFournisseurModalForm = ({ showModal, fournisseurAdded }) => {
  const [fournisseurForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {}, [showModal]);

  const handleCancel = () => {
    closingModal();
    fournisseurAdded(false);
  };
  const closingModal = () => {
    setLoading(false);
    fournisseurForm.resetFields();
  };

  const addFournisseur = async (values) => {
    setLoading(true);
    const response = await FournisseurService.addFournisseur(values);
    if (response.status === 200 || 204) {
      message.info("Fournisseur ajouté avec succès");
    } else message.error("Une erreur est survenue ! ");
    closingModal();
    fournisseurAdded(true);
    setLoading(false);
  };
  

  return (
    <Spin spinning={isLoading} size="large">
      <Modal
        visible={showModal}
        onOk={fournisseurForm.submit}
        onCancel={handleCancel}
        getContainer={false}
        okText="Enregistrer"
        cancelText="Annuler"
        centered
        width={900}
        title={"Ajouter Un fournisseur"}
      >
        <Form
          layout="vertical"
          form={fournisseurForm}
          onFinish={addFournisseur}
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Informations Générales" key="1">
              <Row>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Raison Sociale"
                    name="raison_sociale"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Matricule Fiscal"
                    name="matricule_fiscal"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Registre Du commerce"
                    name="registre_commerce"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>

                <Col span={12} className="px-2">
                  <Form.Item
                    label="Nom"
                    name="nom"
                    rules={[
                      {max :100 ,message:"Maximum de caractère 100 !",},
                      {required :true , message : "Champ obligatoire !"},
                      {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Prénom"
                    name="prenom"
                    rules={[
                      {max :100 ,message:"Maximum de caractère 100 !",},
                      {required :true , message : "Champ obligatoire !"},
                      {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Format invalid!',
                      },
                      {
                        required: true,
                        message: 'champ obligatoire !',
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item                     
                    rules={[
                        {
                          pattern:/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g,
                          message: 'Format invalid!',
                        },
                      ]} 
                    label="Site Web" 
                    name="site">
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>

                <Col span={8} className="px-2">
                  <Form.Item
                    label="Numéro Téléphone "
                    name="telephone_pro"
                    tooltip="Maximum 13 chiffres"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={99999999999999}
                      size="large"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Numéro Personnel"
                    name="telephone_personnel"
                    tooltip="Maximum 13 chiffres"
                  >
                    <InputNumber size="large" max={99999999999999} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item 
                    label="Numéro Fax"
                    name="telephone_fax"
                    tooltip="Maximum 13 chiffres"
                  >
                    <InputNumber
                      min={0}
                      max={99999999999999}
                      size="large"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Adresses du fournisseur" key="2">
              <Row>
                <Col span={24}>
                  <b className="p-2">Adresses du fournisseur :</b>
                </Col>

                <Form.List
                  name="adresse_fournisseurs"
                  label="List des addresses"
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <>
                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              {...restField}
                              name={[name, "pays"]}
                              label="Pays"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                },
                              ]}
                            >
                              <Select size="large" dropdownStyle = {{ position: "fixed" }}>
                                {country.map((c) => (
                                  <Select.Option key={c.id} value={c.name}>
                                    {c.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              {...restField}
                              name={[name, "ville"]}
                              label="Ville"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                          </Col>
                          <Col span={11} className="px-2 mt-2">
                            <Form.Item
                              {...restField}
                              name={[name, "adresse"]}
                              label="Adresse"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                          </Col>
                          <Col span={1} className="px-2 mt-2">
                            <Form.Item label=" ">
                              <MinusCircleOutlined
                                size="large"
                                onClick={() => remove(name)}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ))}
                      <Row>
                        <Col span={12} className="px-2 mt-3">
                          <Button type="dashed" onClick={() => add()}>
                            Ajouter une adresse
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Modal>
    </Spin>
  );
};
export default AddFournisseurModalForm;
