import {
  AppstoreAddOutlined,
  AuditOutlined,
  BankOutlined,
  DiffOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  HddOutlined,
  IdcardOutlined,
  OneToOneOutlined,
  ProfileOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  TeamOutlined,
  SlidersOutlined,
  FileSyncOutlined,
  GoldOutlined,
  PullRequestOutlined,
} from "@ant-design/icons";
import { BackTop, Image, Menu } from "antd";
import { React, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backPhoto from "../../assets/images/logo-lookup.png";
// import backPhoto from "../../assets/images/new_logo.png";
import Can from "../../security/Can";

const { SubMenu } = Menu;

function SideNavigation() {
  const history = useHistory();
  const location = useLocation();
  const [myKey, setMyKey] = useState([]);
  const [openSub, setOpenSub] =  useState([]);
  const navigate = (location) => {
    history.push(location);
  };
  useEffect(() => {
    getRoute();
  }, [location]);

  const getRoute = () => {
    const selectedRoute = [];
    window.scrollTo({ top: 0, behavior: "smooth" });
    switch (location.pathname) {
      case "/":
        selectedRoute.push("1");
        break;
      case "/client":
        selectedRoute.push("2");
        break;
      case "/fournisseur":
        selectedRoute.push("3");
        break;
      case "/bon_Commandes":
        selectedRoute.push("4");
        break;
      case "/bon_Receptions":

        selectedRoute.push("5");
        break;
      case "/facture_achat":
        selectedRoute.push("6");
        break;
      case "/devis_list":
        selectedRoute.push("7");
        break;
      case "/accompte":
        selectedRoute.push("8");
        break;
      case "/v/bon_Commandes":
        selectedRoute.push("9");
        break;
      case "/v/bon_livraisons":
        selectedRoute.push("10");
        break;
      case "/facture_vente":
        selectedRoute.push("11");
        break;
      case "/tickets_caisse":
        selectedRoute.push("12");
        break;
      case "/s/bon_livraisons":
        selectedRoute.push("13");
        break;
      case "/s/factures":
        selectedRoute.push("14");
        break;
      case "/produit":
        selectedRoute.push("15");
        break;
      case "/projet":
        selectedRoute.push("17");
        break;
      case "/employe":
        selectedRoute.push("18");
        break;
      case "/stock":
        selectedRoute.push("20");
        break;
      case "/bon_transfert":
        selectedRoute.push("21");
        break;
      case "/bon_retour":
        selectedRoute.push("22");
        break;
      case "/list/inventaires":
        selectedRoute.push("23");
        break;
      default:
    }
    setMyKey(selectedRoute);
  };

  return (
    <>
      <div className="my-2 py-2 logo ">
        <div className="text-center">
          <Image
            preview={false}
            src={backPhoto}
            className="backGroundPic px-5 "
          />
        </div>
      </div>

      <Menu theme="dark" selectedKeys={myKey} mode="inline">
        <Menu.Item
          key="1"
          icon={<BankOutlined />}
          onClick={() => navigate("/")}
          disabled={!Can("7_1")}
        >
          Acceuil
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<IdcardOutlined />}
          onClick={() => navigate("/client")}
          disabled={!Can("8_1")}
        >
          Clients
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<SolutionOutlined />}
          onClick={() => navigate("/fournisseur")}
          disabled={!Can("9_1")}
        >
          Fournisseur
        </Menu.Item>
        <SubMenu key="Achat" icon={<DollarOutlined />} title="Achat">
          <Menu.Item
            icon={<ShoppingCartOutlined />}
            disabled={!Can("10_1")}
            key="4"
            onClick={() => navigate("/bon_Commandes")}
          >
            Bon de commande
          </Menu.Item>
          <Menu.Item
            icon={<FileDoneOutlined />}
            disabled={!Can("11_1")}
            key="5"
            onClick={() => navigate("/bon_Receptions")}
          >
            Bon de réception
          </Menu.Item>
          <Menu.Item
            icon={<AuditOutlined />}
            disabled={!Can("12_1")}
            key="6"
            onClick={() => navigate("/facture_achat")}
          >
            Facture
          </Menu.Item>
        </SubMenu>
        <SubMenu key="Vente" icon={<DollarOutlined />} title="Vente">
          <Menu.Item
            icon={<DiffOutlined />}
            disabled={!Can("13_1")}
            key="7"
            onClick={() => navigate("/devis_list")}
          >
            Devis
          </Menu.Item>
          <Menu.Item
            icon={<FileDoneOutlined />}
            disabled={!Can("14_1")}
            key="9"
            onClick={() => navigate("/v/bon_Commandes")}
          >
            Bon de commande
          </Menu.Item>
          <Menu.Item
            icon={<ShoppingCartOutlined />}
            disabled={!Can("15_1")}
            key="10"
            onClick={() => navigate("/v/bon_livraisons")}
          >
            Bon de livraison
          </Menu.Item>
          <Menu.Item
            icon={<OneToOneOutlined />}
            disabled={!Can("14_8")}
            key="8"
            onClick={() => navigate("/accompte")}
          >
            Acompte
          </Menu.Item>
          <Menu.Item
            icon={<AuditOutlined />}
            disabled={!Can("16_1")}
            key="11"
            onClick={() => navigate("/facture_vente")}
          >
            Facture
          </Menu.Item>
        </SubMenu>
        <SubMenu key="Direct" icon={<SendOutlined />} title="Direct">
          <Menu.Item
            icon={<FileTextOutlined />}
            disabled={!Can("17_1")}
            key="12"
            onClick={() => navigate("/tickets_caisse")}
          >
            Ticket de caisse
          </Menu.Item>
          <Menu.Item
            icon={<ShoppingCartOutlined />}
            disabled={!Can("18_1")}
            key="13"
            onClick={() => navigate("/s/bon_livraisons")}
          >
            Bon de livraison simple
          </Menu.Item>
          <Menu.Item
            icon={<AuditOutlined />}
            disabled={!Can("19_1")}
            key="14"
            onClick={() => navigate("/s/factures")}
          >
            Facture simple
          </Menu.Item>
        </SubMenu>
        <SubMenu key="Stock" icon={<HddOutlined />} title="Stock">
          <Menu.Item
            key="20"
            icon={<GoldOutlined />}
            disabled={!Can("20_1")}
            onClick={() => navigate("/stock")}
          >
            Stock
          </Menu.Item>
          <Menu.Item
            key="21"
            icon={<FileSyncOutlined />}
            disabled={!Can("21_1")}
            onClick={() => navigate("/bon_transfert")}
          >
            Bon de transfert
          </Menu.Item>
          <Menu.Item
            key="22"
            icon={<PullRequestOutlined />}
            disabled={!Can("22_1")}
            onClick={() => navigate("/bon_retour")}
          >
            Bon de retour
          </Menu.Item>
          <Menu.Item
            key="23"
            icon={<SlidersOutlined />}
            disabled={!Can("23_1")}
            onClick={() => navigate("/list/inventaires")}
          >
            Inventaire
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="15"
          icon={<AppstoreAddOutlined />}
          disabled={!Can("24_1")}
          onClick={() => navigate("/produit")}
        >
          Produit et services
        </Menu.Item>

        <Menu.Item
          key="17"
          icon={<ProfileOutlined />}
          disabled={!Can("25_1")}
          onClick={() => navigate("/projet")}
        >
          Projet
        </Menu.Item>
        <Menu.Item
          key="18"
          icon={<TeamOutlined />}
          disabled={!Can("26_1")}
          onClick={() => navigate("/employe")}
        >
          Employées
        </Menu.Item>
        <Menu.Item
          key="19"
          icon={<TeamOutlined />}
          disabled={!Can("27_1")}
          onClick={() => navigate("/users")}
        >
          Utilisateurs
        </Menu.Item>
      </Menu>
    </>
  );
}
export default SideNavigation;
