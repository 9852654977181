import {
  CheckOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Can from "../../../security/Can";
import BonRetourService from "../../../services/bonRetour.service";
import BonTransfertService from "../../../services/bonTransfert.service";
import EntrepriseService from "../../../services/entreprise.service";
import ProduitService from "../../../services/produit.service";
import StockService from "../../../services/Stock.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheStockDetails from "../../uiHelpers/Fiches/FicheStockDetails";
import FicheTransfertHeader from "../../uiHelpers/Fiches/FicheTransfertHeader";
import NotAuthorized from "../../uiHelpers/NotAuthorized";

function BonRetour() {
  const [productsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [entreprise, setEntreprise] = useState({});
  const [productsModal, setProductsModal] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [bnTransfertId, setbnTransfert] = useState();
  const [initalQuantite, setInitalQuantite] = useState();
  const [stores, setStores] = useState([]);

  const history = useHistory();

  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    fodec: 0,
    tva: 0,
    totalTimbreFiscal: "0.600",
  });

  const isVente = true;

  useEffect(() => {
    loadProduits();
    loadEntreprise();
    getThemeMode();
    getStoreNames();
    setUpdate();
    return () => {
      clearStates();
    };
  }, []);

  const getStoreNames = async () => {
    setLoading(true);
    const response = await StockService.getStores();
    if (response.status === 200 || 204) setStores(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const setUpdate = async () => {
    const id = location.state?.id;
    setLoading(true);
    if (!location.state?.editMode) {
      await BonTransfertService.getBonTransfert(id, async (response) => {
        if (response.status === 200 || 204) {
          const res = response.data;
          res.produit_achats = response.produit_bon_transferts;
          const validReception = await loadAllBonReception(id);
          const productList = { ...res };
          productList.produit_achats = productList.produit_bon_transferts;
          productList.date = moment(productList.date);
          const tmp = productList.to_store_id;
          productList.to_store_id = productList.from_store_id;
          productList.from_store_id = tmp;
          setProductsAndQuantites(productList, validReception);
          loadProduits();
          setEditMode(true);
          generateReference();
        } else message.error("Une erreur est survenue ! ");
      });
    } else {
      await BonRetourService.getBonRetour(id, async (response) => {
        if (response.status === 200 || 204) {
          const res = response.data;
          res.produit_achats = res.produit_bon_retours;
          const validReception = await loadAllBonReception(res.bnTransfertId);
          const productList = { ...res };
          productList.date = moment(productList.date);
          setProductsAndQuantitesUpdate(productList, validReception);
          loadProduits();
          setUpdateId(id);
          setEditMode(true);
        } else message.error("Une erreur est survenue ! ");
      });
    }
    setLoading(false);
  };
  const setProductsAndQuantites = (productList, validReception) => {
    setbnTransfert(productList.id);
    const ids = [];
    for (const element of productList.produit_achats) {
      ids.push(element.produitId);
    }
    setSelectedIds(ids);
    productsForm.setFieldsValue(productList);
  };
  const setProductsAndQuantitesUpdate = (productList, validReception) => {
    const ids = [];
    for (const element of productList.produit_achats) {
      ids.push(element.produitId);
    }
    setSelectedIds(ids);
    productsForm.setFieldsValue(productList);
  };

  const generateReference = async () => {
    await BonRetourService.getLast((response) => {
      if (response.status === 200 || 204) {
        if (response.data.length === 0) {
          var nbr = "1";
          var reference = "BR-" + nbr.padStart(4, "0");
        } else {
          const lastRef = response.data.reference;
          const refNbr = Number(lastRef.substring(3, 7)) + 1 + "";
          var reference = "BR-" + refNbr.padStart(4, "0");
        }
        productsForm.setFieldsValue({
          reference: reference,
          date: moment(new Date()),
        });
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };

  const loadAllBonReception = async (id) => {
    setLoading(true);
    const response = await BonRetourService.getActiveReception(id);
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const loadProduits = async (id) => {
    setLoading(true);
    const response = await ProduitService.getProduits();
    if (response.status === 200 || 204)
      setProductList(() => {
        return formatteProductsToDataTable(response.data);
      });
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const formatteProductsToDataTable = (produits) => {
    for (const produit of produits) {
      produit.showType = produit.type ? "Service" : "Produit";
      produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
      produit.htAchat = produit.produit_achats[0]?.prixHT;
      produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
      produit.showFournisseur =
        produit.produit_achats[0]?.fournisseur?.raison_sociale ??
        "Produit proprietere";
      produit.ttcVente = produit.produit_ventes[0].prixTTC;
      produit.htVente = produit.produit_ventes[0].prixHT;
    }
    return produits;
  };

  const selectProduct = (produit) => {
    setSelectedIds((prev) => [...prev, produit.id]);
    formatteProduct(produit);
  };

  const removeElement = (produitId) => {
    const list = selectedIds;
    setSelectedIds(list.filter((idP) => idP !== produitId));
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };

  const formatteProduct = (produit) => {
    const qte = produit?.quantite ?? 1;
    const product = {
      produitId: produit?.id,
      libelle: produit?.libelle,
      quantite: Number(qte),
    };
    const productList = productsForm.getFieldValue("produit_achats")
      ? productsForm.getFieldValue("produit_achats")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produit_achats: productList });
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const handleCancel = () => {
    setProductsModal(false);
  };

  const getProductId = (index, name) => {
    return productsForm.getFieldValue("produit_achats")[index]?.produitId;
  };

  const addBnRetour = async () => {
    setLoading(true);
    const product = productsForm.getFieldsValue();
    const bonRetour = { ...product };
    bonRetour.etat = 0;
    for (const element of bonRetour.produit_achats) 
      delete element.id;
    
    bonRetour.produit_bon_retours = bonRetour.produit_achats;
    delete bonRetour.produit_achats;
    bonRetour.bntransfertId = bnTransfertId;
    const updateId = location.state.id;
    const response = !location.state?.editMode
      ? await BonRetourService.addBonRetour(bonRetour)
      : await BonRetourService.updateBonRetour(updateId, bonRetour);
    if (response.status === 200 || 204) {
      location.state?.editMode
        ? message.info("Bon de retour Mise a jour Avec succès")
        : message.info("Bon de retour Ajouté Avec succès");
      history.push("/bon_retour");
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const getMax = (index) => {
    const max = productsForm.getFieldValue("produit_achats")[index]?.quantite;
    return max;
  };
  const getLibelle = (index) => {
    return productsForm.getFieldValue("produit_achats")[index]?.libelle;
  };

  const toSelect = () => {
    return (
      <Select size="large" dropdownStyle = {{ position: "fixed" }}>
        {stores &&
          stores.map((store) => (
            <Select.Option disabled={compareSelects(store.id)} value={store.id}>
              {store.nom}
            </Select.Option>
          ))}
      </Select>
    );
  };

  const fromSelectChanged = () => {
    const storeId = productsForm.getFieldValue("from_store_id");
    loadProduits(storeId);
    toSelect();
  };

  const compareSelects = (id) => {
    const storeFromId = productsForm.getFieldValue("from_store_id");
    const result = storeFromId === id;
    return result;
  };

  const disableForm = () => {
    return selectedIds.length === 0;
  }

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      key: "1",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              disabled={isSelected(record.id)}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => selectProduct(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {Can("22_2") ? (
        <div className="px-5">
          <Spin spinning={isLoading} size="large">
            <Card className="w-100 card-shadow">
              <Form
                layout="vertical"
                form={productsForm}
                onFinish={addBnRetour}
              >
                <Row>
                  <FicheTransfertHeader
                    moduleName={"Bon De Retour"}
                    entreprise={entreprise}
                  ></FicheTransfertHeader>
                </Row>
                <Row>
                  <Col span={8} className="px-2 pt-3">
                    <Col span={24}>
                      <Form.Item
                        label="Retour Du Store :"
                        name="from_store_id"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          onChange={() => fromSelectChanged()}
                        >
                          {stores &&
                            stores.map((store) => (
                              <Select.Option value={store.id}>
                                {store.nom}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Vers :"
                        name="to_store_id"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        {toSelect()}
                      </Form.Item>
                    </Col>
                  </Col>
                  <Col span={8} offset={8} className="px-2 pt-3">
                    <FicheStockDetails></FicheStockDetails>
                  </Col>
                </Row>

                <Row>
                  <Col span="24" className="px-4">
                    <table
                      className={` mb-5 table ${
                        isDarkMode ? "table-dark" : ""
                      } `}
                    >
                      <thead className="pl-2">
                        <Row>
                          <Col span={1}>
                            <b>#</b>
                          </Col>
                          <Col span={19}>
                            <b>Libelle</b>
                          </Col>

                          <Col span={2}>
                            <b>Quantité</b>
                          </Col>
                          <Col className="px-3" span={2}>
                            <Tooltip title="Ajouter un produit">
                              <Button
                                shape="circle"
                                icon={<PlusCircleOutlined />}
                                onClick={() => setProductsModal(true)}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      </thead>
                      <tbody>
                        <Form.List name="produit_achats" label="">
                          {(fields, { add, remove }) => (
                            <>
                              <Row className="pb-1 mb-1 ">
                                {fields.map(
                                  ({ key, name, ...restField }, index) => (
                                    <>
                                      <Col className="mb-0 pb-0" span={1}>
                                        {index + 1}
                                      </Col>
                                      <Col className="mb-0 pb-0" span={19}>
                                        <Text className="mb-0 pb-0">
                                          {getLibelle(index)}
                                        </Text>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "libelle"]}
                                          style={{ height: 0 }}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <Input
                                            type="hidden"
                                            bordered={false}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantite"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            style={{ width: 80 }}
                                            className="w-125px"
                                            min={0}
                                            max={getMax(index)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0 px-3" span={2}>
                                        <Tooltip title="Supprimer le produit">
                                          <Button
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                              removeElement(
                                                getProductId(index)
                                              );
                                              remove(name);
                                            }}
                                          />
                                        </Tooltip>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <FicheFooter entreprise={entreprise}></FicheFooter>

                <Row className="d-flex flex-row-reverse pt-5 pb-2">
                  <Button
                    className="mx-2"
                    type="primary"
                    onClick={productsForm.submit}
                    disabled={disableForm()}

                    
                  >
                    Enregistrer
                  </Button>
                  
                </Row>
              </Form>
            </Card>

            <Modal
              visible={productsModal}
              getContainer={false}
              centered
              footer={null}
              onCancel={handleCancel}
              title={"Produits"}
              width={1000}
            >
              <Spin spinning={isLoading} size="large">
                <Card title={"Liste des produits"}>
                  <AppTable data={productList} columns={ProduitColumns} />
                </Card>
              </Spin>
            </Modal>
          </Spin>
        </div>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
}

export default BonRetour;
