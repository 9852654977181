import {
  CheckOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePptOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonCommandeService from "../../../services/bonCommande.service";
import BonReceptionService from "../../../services/bonReception.service";
import FournisseurService from "../../../services/fournisseur.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";

const url = environment.serverUrl;

const DashboardBonReception = () => {
  const [commandeForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [bonCommandeModal, setBonCommandeModal] = useState(false);
  const [bonCommandes, setBonCommandes] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [reference, setReference] = useState();

  useEffect(() => {
    loadBonReception();
    loadFournisseurs();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadBonReception = async () => {
    setLoading(true);
    await BonReceptionService.getBonReceptions((response) => {
      if (response.status === 200 || 204) {
        FournisseurService.getFournisseurNames((resp) => {
          if (response.status === 200 || 204) {
            initTableData(response.data, resp.data);
          } else message.error("Une erreur est survenue ! ");
        });
      } else message.error("Une erreur est survenue ! ");
    });
    setLoading(false);
  };

  const loadFournisseurs = async (data) => {
    setLoading(true);
  };
  const initTableData = async (data, fournisseurs) => {
    setLoading(true);
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.refBonCommande =  await getCommandeReference( element.bonCommandeId );
      console.log(element);
      const fournisseurId = element.bon_reception_produits[0]?.fournisseurId ?? -1;
      element.fournisseur = getFournisseurName(fournisseurId, fournisseurs);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    setDataTable(list);
    setLoading(false);
  };
  const getCommandeReference = async (bonCommandeId) => {
    const response = await BonCommandeService.getReference(bonCommandeId);
    return response.data.reference;
  };
  const getFournisseurName = (id, list) => {
    if (id === -1) return "";
    for (const element of list) {
      if (element.id === id) return element.raison_sociale;
    }
  };

  const formatteEtat = (etat) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        return "Livré";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const changeBonStatus = async (id, etat) => {
    const response = await BonReceptionService.changeEtat(id, etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Activé" : "Desactivé";

      message.info("Bon de commande " + msg);
    } else message.error("Une erreur est survenue ! ");
    loadBonReception();
  };

  const navigate = (location) => {
    history.push(location);
  };

  const goToBonReception = (record) => {
    const id = record.id;
    const editMode = false;
    history.push("/bonReception", { id, editMode });
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    const editMode = true;
    history.push("/bonReception", { id, editMode });
  };

  const openBonReceptionModal = async () => {
    setLoading(true);
    await BonCommandeService.getActiveBonCommandeForReception((response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        for (const element of data) {
          element.showDate = moment(new Date(element.date)).format(
            "YYYY-MM-DD"
          );
        }
        console.log("response.data",response.data)
        setBonCommandes(response.data);
        setBonCommandeModal(true);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };
  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference);
  };
  const downloadFile = () => {
    window.open(
      url + "Files/Achat/BonReception/" + reference + ".pdf",
      "download"
    );
  };

  const bonReceptionsCols = [
    {
      title: "Référence bon de Réception",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Référence Bon de commande",
      dataIndex: "refBonCommande",
      sorter: (a, b) => a?.refBonCommande?.localeCompare(b?.refBonCommande),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Fournisseur",
      dataIndex: "fournisseur",
      sorter: (a, b) => a?.fournisseur?.localeCompare(b?.fournisseur),
    },

    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)}</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a.devise.localeCompare(b.devise),
    },{
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={!Can("11_4")}>
              <Menu.Item
                className="px-5"
                key="1"
                disabled={record.etat === 1}
                onClick={() => changeBonStatus(record.id, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="2"
                disabled={record.etat === -1}
                onClick={() => changeBonStatus(record.id, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Action",
      key: "action",
      width:"15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("11_5")}
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              disabled={record.etat === 1 || !Can("11_3")}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const bonCommandeCols = [
    {
      title: "Référence Bon de commande",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Fournisseur",
      dataIndex: "four_raison_sociale",
      sorter: (a, b) => a?.four_raison_sociale?.localeCompare(b?.four_raison_sociale),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)}</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a?.devise?.localeCompare(b?.devise),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => goToBonReception(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    commandeForm.resetFields();
    setBonCommandeModal(false);
  };

  return (
    <>
      {Can("11_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Bon de Réception"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("11_2")}
                onClick={() => openBonReceptionModal()}
              >
                Ajouter Un Bon De Réception
              </Button>
            }
          >
            <TableWithFiltres data={dataTable} columns={bonReceptionsCols} />
          </Card>
          <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">Bon de commande : {reference}</div>
                  <div className="px-5 ">
                    <Button
                      onClick={() => downloadFile()}
                      icon={<DownloadOutlined />}
                    >
                      Télécharger
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={1000}
            onCancel={() => setShowModal(false)}
          >
            {showModal && (
              <div className="heigt-500px">
                <PdfViewerModal
                  diractory="Files/Achat/BonReception/"
                  reference={reference}
                ></PdfViewerModal>
              </div>
            )}
          </Modal>
          <Modal
            visible={bonCommandeModal}
            getContainer={false}
            centered
            onOk={commandeForm.submit}
            onCancel={handleCancel}
            title={"Liste des bons de commandes"}
            width={900}
          >
            <Spin spinning={isLoading} size="large">
              <TableWithFiltres data={bonCommandes} columns={bonCommandeCols} />
            </Spin>
          </Modal>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardBonReception;
