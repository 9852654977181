import {
  DownloadOutlined,
  EditOutlined,
  FilePptOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import InventaireService from "../../../services/inventaire.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";

const url = environment.serverUrl;

const DashboardInventaire = () => {
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [reference, setReference] = useState();

  useEffect(() => {
    loadInventaire();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadInventaire = async () => {
    setLoading(true);
    await InventaireService.getAllInventaire(async (response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        initTableData(data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };

  const initTableData = (data) => {
    for (const element of data) {
      element.store = element.store.nom;
      element.responsable = element.employe.nom + " " + element.employe.prenom;
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
    }
    setDataTable(data);
  };

  const formatteEtat = (etat) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        return "Activé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const changeDevisStatus = async (id, etat) => {
    const response = await InventaireService.changeInventaireStatus(id, etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Activé" : "Desactivé";
      message.info("Inventaire " + msg);
    } else message.error("Une erreur est survenue ! ");
    loadInventaire();
  };

  const navigate = (location) => {
    history.push(location);
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    history.push("/Inventaire", { id });
  };
  const BonCommandes = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Store",
      dataIndex: "store",
      sorter: (a, b) => a.from.localeCompare(b.from),
    },
    {
      title: "Responsable",
      dataIndex: "responsable",
      sorter: (a, b) => a.to.localeCompare(b.to),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={record.etat === 1 || !Can("23_4")}>
              <Menu.Item
                className="px-5"
                key="1"
                onClick={() => changeDevisStatus(record.id, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                disabled={record.etat === -1}
                className="px-5"
                key="2"
                onClick={() => changeDevisStatus(record.id, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("23_5")}
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              disabled={record.etat === 1 || !Can("23_3")}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference);
  };
  const downloadFile = () => {
    window.open(
      url + "Files/stock/Inventaire/" + reference + ".pdf",
      "download"
    );
  };

  return (
    <>
      {Can("23_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Inventaire"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("23_2")}
                onClick={() => navigate("/Inventaire")}
              >
                Ajouter un Inventaire
              </Button>
            }
          >
            <TableWithFiltres data={dataTable} columns={BonCommandes} />
          </Card>
          <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">Inventaire : {reference}</div>
                  <div className="px-5 ">
                    <Button
                      onClick={() => downloadFile()}
                      icon={<DownloadOutlined />}
                    >
                      Télécharger
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={1000}
            onCancel={() => setShowModal(false)}
          >
            {showModal && (
              <div className="heigt-500px">
                <PdfViewerModal
                  diractory="Files/stock/Inventaire/"
                  reference={reference}
                ></PdfViewerModal>
              </div>
            )}
          </Modal>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardInventaire;
