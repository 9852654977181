const unite = [
    { id: 1, code_fr: "Pièce" },
    { id: 2, code_fr: "Métre" },
    { id: 3, code_fr: "Métre Carré" },
    { id: 4, code_fr: "Métre Cube" },
    { id: 5, code_fr: "Centimétre" },
    { id: 6, code_fr: "Kilométre" },
    { id: 7, code_fr: "Homme/Heure" },
    { id: 8, code_fr: "Homme/Jour" },
    { id: 9, code_fr: "Homme/Mois" },
    { id: 10, code_fr: "Homme/Année" }
  ];
  
  export default unite;
  