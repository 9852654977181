import { Table, Input, Button, Space, Empty, notification } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import BonCommandeExtraData from "./BonCommandeExtraData";

export default class TableWithFiltresWithExpand extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    columns: [],
    bonCommandeId: "",
    expandKeys : [],
    activate : false
  };

  componentDidMount() {
    const cols = this.props.columns;
    const list = [];
    for (const col of cols) {
      if (
        col.title !== "Action" &&
        col.title !== "Etat" &&
        col.title !== "Etat Paiement" &&
        col.title !== "Type"
      ) {
        const newCol = { ...col, ...this.getColumnSearchProps(col.dataIndex) };
        list.push(newCol);
      } else {
        list.push(col);
      }
    }
    this.setState({ columns: list });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, width: "300px" }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <div className="d-flex flex-row-reverse">
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            className="px-3"
          >
            Rechercher
          </Button>
          <Button
            onClick={() =>
              this.handleReset(selectedKeys, confirm, dataIndex, clearFilters)
            }
            className="px-3 mx-2"
          >
            Annuler
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (selectedKeys, confirm, dataIndex, clearFilters) => {
    clearFilters();
    confirm();
    this.setState({
      searchText: "",
      searchedColumn: dataIndex,
    });
  };
  expandClicked = (expanded, row) => {
    this.setState({ bonCommandeId: row.id , montantTotal : row.montant_total });
  };

  closeExpanded = (row) => {
    const list = [];
    console.log(row);
    if( row.length > 1 ) this.showNotificationWarning();
    if( this.state.activate ){
      this.setState({expandKeys:[]})
    }
    if(row[0] !== undefined ){
      list.push(row[0]);
      this.setState({expandKeys:list , activate: true})
    } 
  };

  showNotificationWarning = () => {
    notification.info({
      message: 'Attentions',
      description: 'Veuillez fermer les détails du bon de commande ouvert pour en ouvrir un autre.',
    });
  }

  render() {
    const locale = {
      emptyText: (
        <Empty description={"Le tableau est vide pour le moment "}></Empty>
      ),
    };
    return (
      <Table
        rowKey={(record) => record.id}
        locale={locale}
        pagination={{ position: ["bottomCenter"], pageSize: 5 }}
        showSorterTooltip={false}
        expandedRowKeys={this.state.expandKeys}
        columns={this.state.columns}
        dataSource={this.props.data}
        onExpand={(expanded, row) => this.expandClicked(expanded, row)}
        onExpandedRowsChange={(row) => this.closeExpanded(row)}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <BonCommandeExtraData bonCommandeId={this.state.bonCommandeId} montantTotal={this.state.montantTotal}></BonCommandeExtraData>
            </>
          ),
        }}
      />
    );
  }
}
