import {
    Col,
    DatePicker,
    Form,
    Input, Row, Select, Typography
} from "antd";
import currencys from '../../../helpers/currency';
import React from "react";

const { Text, Title } = Typography;

function FicheDetails({projetList , currencyChanged, showLivraisonDate}) {

  const formCurrencyChanged = () =>{
    currencyChanged()
  }
  
  return (
    <Row>
      <Col span={16} className="pr-02">
        <Form.Item
          label="Date :"
          name="date"
          rules={[
            {
              required: true,
              message: "Champ obligatoire !",
            },
          ]}
        >
          <DatePicker placeholder=" " size="large" className="w-100" />
        </Form.Item>
      </Col>
      <Col span={8} className="pl-02">
        <Form.Item
          label="Devise :"
          name="devise"
          rules={[
            {
              required: true,
              message: "Champ obligatoire !",
            },
          ]}
        >

          <Select size="large" onChange={()  => formCurrencyChanged()} dropdownStyle = {{ position: "fixed" }}>
            { currencys && currencys.map( currency => (
              <Select.Option  key={currency.id} value={currency.code}>{currency.code}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Référence :"
          name="reference"
        >
          <Input type={"text"} size="large" className="w-100" disabled />
        </Form.Item>
      </Col>

      { showLivraisonDate &&
        <Col span={24}>
          <Form.Item
            label="Date de livraison :"
            name="date_livraison"
          >
            <DatePicker placeholder=" " size="large" className="w-100" />
          </Form.Item>
        </Col>
      }
      {projetList &&
        <Col span={24}>
          <Form.Item
            label="Projet :"
            name="projetId"
          >
          <Select size="large" dropdownStyle = {{ position: "fixed" }}>
            {
            projetList.map(projet => (
              <Select.Option key={projet.id} value={projet.id}>
                {projet.titre} 
              </Select.Option>   
            ))
          }
          </Select>
          </Form.Item>
        </Col>
      }
      <Col span={24}>
        <Form.Item
          label="Note :"
          name="note"
        >
          <Input.TextArea rows={4} type="text" maxLength={255} />
        </Form.Item>
      </Col>
    </Row>
  );
}
export default FicheDetails;
