import {
  DownloadOutlined, EditOutlined, FilePptOutlined , QuestionCircleOutlined ,CopyOutlined
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, message, Modal, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonCommandeService from "../../../services/bonCommande.service";
import FournisseurService from "../../../services/fournisseur.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";

const url = environment.serverUrl;

const DashboardBonCommande = () => {
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reference,setReference] = useState();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
      loadBonCommande();
      loadFournisseurs();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadBonCommande = async () => {
    setLoading(true);
    await BonCommandeService.getBonCommandes((response)=> {
      if (response.status === 200 || 204) {
        FournisseurService.getFournisseurNames( (resp) => {
          if (response.status === 200 || 204) {
            initTableData(response.data , resp.data)
          }else message.error("Une erreur est survenue ! ");
        });
      }else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };

  const loadFournisseurs = async (data) => {
    setLoading(true);

  };

  const initTableData = (data ,fournisseurs) => {
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.dateLivraison = moment(new Date(element?.date_livraison)).format("YYYY-MM-DD");
      const fournisseurId = element.produit_achats[0]?.fournisseurId ?? 0;
      element.fournisseurId = element.produit_achats[0]?.fournisseurId ?? 0;
      element.fournisseur = getFournisseurName(fournisseurId,fournisseurs);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element)
    }
    setDataTable(list);
  };

  const getFournisseurName = (id ,list) => {
    for (const element of list) {
      if(element.id === id ) return element.raison_sociale
    }
  }

  const formatteEtat = (etat , Fstatus , Lstatus) => {
    switch( etat ){
      case 0 : return "En cours";
      case 1 : {
        if( Fstatus ) return "Facturé";
        if( Lstatus ) return "Livré"
        else return "Activé";
      }
      case -1 : return "Annulé";
      default : return ""
    }
  }

  const changeStateBonCommande = async (id ,etat) => {
    const response = await BonCommandeService.changeStateBonCommande(id,etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Activé" : "Désactivé"  
       message.info("Bon de commande "+ msg);
    } else message.error("Une erreur est survenue ! ");
    loadBonCommande();
  }

  const navigate = (location) => {
    history.push(location);
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    history.push("/bonCommande", {id});
  }

  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference)
  } 
  const downloadFile = () => {
    window.open(url+"Files/Achat/BonCommande/"+reference+".pdf", 'download');
  }

  const confirmDuplication = (record) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined  />,
      content: "Êtes-vous sûr de dupliquer ce bon de commande ?",
      okText: "Oui",
      onOk: () => duplicateData(record),
      cancelText: "Non",
    });
  };
  const duplicateData = async (record) => {
    const response = await BonCommandeService.duplicate(record.id);
    if(response.data){
      const id  = response.data.id;
      const duplicationMode = true
      message.success("Duplication avec succès");
      history.push("/bonCommande", {id,duplicationMode});
    }else message.error("Une erreur est survenue ! ");
  }

  const BonCommandes = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Date Livraison",
      dataIndex: "dateLivraison",
      sorter: (a, b) => a?.dateLivraison?.localeCompare(b?.dateLivraison),
    },
    {
      title: "Fournisseur",
      dataIndex: "fournisseur",
      sorter: (a, b) => a?.fournisseur?.localeCompare(b?.fournisseur),
    },
    
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => <b>{Number(record.montant_total).toFixed(3)} {record.devise}</b>,
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a.devise.localeCompare(b.devise),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => <Dropdown.Button overlay={
        <Menu disabled={!Can("10_4") || record.p_status || record.r_status} >
          <Menu.Item disabled={record.etat === 1} className="px-5" key="1" onClick={()=> changeStateBonCommande(record.id ,1)}>Validé</Menu.Item>
          <Menu.Item disabled={record.etat === -1}  className="px-5" key="2" onClick={()=> changeStateBonCommande(record.id ,-1)}>Annulé</Menu.Item>
        </Menu>
      }>{formatteEtat(record.etat , record.p_status , record.r_status)}</Dropdown.Button>
      ,
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Action",
      key: "action",
      width:"15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.etat === 1 || !Can("10_3")}
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];




  return (
    <>
    {Can("10_1") ? (
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Bon de commande"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              disabled={!Can("10_2")}
              type="primary"
              onClick={() => navigate("/bonCommande")}
            >
              Ajouter Un Bon De Commande
            </Button>
          }
        >
          <TableWithFiltres data={dataTable} columns={BonCommandes} />
        </Card>
        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de commande : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Télécharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
      >
        { showModal && 
          <div className="heigt-500px">
            <PdfViewerModal 
              diractory="Files/Achat/BonCommande/" 
              reference={reference}  
            ></PdfViewerModal>
          </div>
        }
        

      </Modal>
      </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardBonCommande;
