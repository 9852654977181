import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin, Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import country from "../../../helpers/country";
import Can from "../../../security/Can";
import EmployeService from "../../../services/employe.service";
import AppTable from "../../uiHelpers/AppTabe";
import NotAuthorized from "../../uiHelpers/NotAuthorized";



const Employe = () => {
  const [employeForm] = Form.useForm();
  const [employeModal, setEmployeModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [switchValue, setSwitchValue] = useState("unchecked");


  useEffect(() => {
    loadEmployes();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadEmployes = async () => {
    setLoading(true);
    const response = await EmployeService.getEmployes();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const initTableData = (data) => {
    setDataTable(data);
  };

  const showEmployeModal = () => {
    setEmployeModal(true);
  };

  const handleOk = () => {
    setEmployeModal(false);
  };

  const updateMode = (record) => {
    setEmployeModal(true);
    const checkedSwitch = record.etat ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    employeForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setEmployeModal(false);
    clearData();
  };

  const addEmploye = async (values) => {
    setLoading(true);
    var data = values;
    data.etat = data.etat ? data.etat : false;
    const response = editMode
      ? await EmployeService.updateEmploye(updateId, data)
      : await EmployeService.addEmploye(data);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec succès");
      else message.info("Employé ajouté avec succès");
    } else message.error("Une erreur est survenue ! ");

    closingModal();
  };

  const closingModal = () => {
    loadEmployes();
    setEmployeModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    employeForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const EmployeColumns = [
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Téléphone ",
      dataIndex: "telephone",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("26_2")}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("26_7")}
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette Employé ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const deleteItem = async (item) => {
    const response = await EmployeService.deleteEmploye(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec succès");
      loadEmployes();
    } else message.error("Une erreur est survenue ! ");
  };

  return (
    <>
    {Can("26_1") ? (
      <Spin spinning={isLoading} size="large">
        <Card
          
          title={"Employés"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              disabled={!Can("26_2")}
              onClick={() => showEmployeModal()}
            >
              Ajouter un Employé
            </Button>
          }
        >
          <AppTable data={dataTable} columns={EmployeColumns} />
          <Form layout="vertical" form={employeForm} onFinish={addEmploye}>
            <Modal
              visible={employeModal}
              onOk={employeForm.submit}
              onCancel={handleCancel}
              getContainer={false}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={900}
              title={editMode ? "Modifier l'employé" : "Ajouter un employé"}
            >
              <Row>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Nom"
                    name="nom"
                    rules={[
                      {max :100 ,message:"Maximum de caractère 100 !",},
                      {required :true , message : "Champ obligatoire !"},
                      {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Prénom"
                    name="prenom"
                    rules={[
                      {max :100 ,message:"Maximum de caractère 100 !",},
                      {required :true , message : "Champ obligatoire !"},
                      {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Format invalid!',
                      },
                      {
                        required: true,
                        message: 'champ obligatoire !',
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Mot de passe"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input.Password size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>

                <Col span={8} className="px-2">
                  <Form.Item
                    label="Pays"
                    name="pays"
                    rules={[
                      {
                        required: true,
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                      },
                    ]}
                  >
                    <Select size="large" dropdownStyle = {{ position: "fixed" }}>
                      {country.map((c) => (
                        <Select.Option key={c.id} value={c.name}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Ville"
                    name="ville"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Numéro Téléphone "
                    name="telephone"
                    rules={[
                      {
                        required: true,
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                      },
                    ]}
                  >
                  <InputNumber
                    size="large"
                    style={{ width: "100%" }}
                    min={0}
                    max={99999999999999}
                  />
                  </Form.Item>
                </Col>
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Adresse"
                    name="adresse"
                    rules={[
                      {
                        required: true,
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </Form>
        </Card>
      </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default Employe;
