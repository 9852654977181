import { Card, Col, Row, Tabs } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import Can from "../../../security/Can";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import Categorie from "./tabs/Categorie";
import CompteBancaire from "./tabs/CompteBancaire";
import Entreprise from "./tabs/Entreprise";
import FormuleGratuite from "./tabs/FormuleGratuite";
import GroupeClient from "./tabs/GroupeClient";
import Marque from "./tabs/Marque";
import Taxes from "./tabs/Taxes";
import Tva from "./tabs/Tva";
const { TabPane } = Tabs;

const Parametres = ({ methods, name }) => {
  const changeColor = (color) => {
    localStorage.setItem("COLOR_THEME", color);
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };
  const changeShowMode = (mode) => {
    localStorage.removeItem("SHOW_MODE")
    localStorage.setItem("SHOW_MODE", mode);
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  const changeFactureMode = ( mode ) => {
    localStorage.removeItem("SHOW_FAC_MODE")
    localStorage.setItem("SHOW_FAC_MODE", mode);
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  return (
    <Tabs defaultActiveKey="1" centered>
      <TabPane tab="Entreprise" key="1" disabled={!Can("1_1")}>
        {Can("1_1") ? (
          <Entreprise />
        ) : (
          <NotAuthorized></NotAuthorized>
        )}
      </TabPane>
      <TabPane tab="Taxes et TVA" key="2" disabled={!Can("2_1")}>
        {Can("2_1") ? (
          <>
            <Tva />
            <br />
            <Taxes />
          </>
        ) : (
          <NotAuthorized></NotAuthorized>
        )}
      </TabPane>
      <TabPane tab="Marques et Catégories" key="3" disabled={!Can("3_1")}>
        {Can("3_1") ? (
          <>
            <Marque />
            <br />
            <Categorie />
          </>
        ) : (
          <NotAuthorized></NotAuthorized>
        )}
      </TabPane>
      <TabPane tab="Groupe clients et formule de gratuité" key="4" disabled={!Can("3_1")}>
        {Can("3_1") ? (
          <>
            <GroupeClient />
            <br />
            <FormuleGratuite></FormuleGratuite>
          </>
        ) : (
          <NotAuthorized></NotAuthorized>
        )}
      </TabPane>

      <TabPane tab="Compte Bancaire" key="5" disabled={!Can("4_1")}>
        {Can("4_1") ? <CompteBancaire /> : <NotAuthorized></NotAuthorized>}
      </TabPane>
      <TabPane tab="Paramètres généraux" key="6">

          <Card title={"Paramètres généraux"}>
            <Row>
              <Col span={4} className="px-2">
                <h5>Couleur :</h5>
              </Col>
              <Col span={4} className="px-2">
                <Avatar
                  className="pointer"
                  onClick={() => changeColor("PURPLE")}
                  style={{ color: "#fff", backgroundColor: "#db2dd7" }}
                >
                  P
                </Avatar>
              </Col>
              <Col span={4} className="px-2">
                <Avatar
                  className="pointer"
                  onClick={() => changeColor("BLUE")}
                  style={{ color: "#fff", backgroundColor: "#1890ff" }}
                >
                  B
                </Avatar>
              </Col>
              <Col span={4} className="px-2">
                <Avatar
                  className="pointer"
                  onClick={() => changeColor("GREEN")}
                  style={{ color: "#fff", backgroundColor: "#66d132" }}
                >
                  G
                </Avatar>
              </Col>
              <Col span={4} className="px-2">
                <Avatar
                  className="pointer"
                  onClick={() => changeColor("YELLOW")}
                  style={{ color: "#fff", backgroundColor: "#faad14" }}
                >
                  Y
                </Avatar>
              </Col>
              <Col span={4} className="px-2">
                <Avatar
                  className="pointer"
                  onClick={() => changeColor("DARK")}
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  D
                </Avatar>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col span={4} className="px-2">
                <h5>Langue :</h5>
              </Col>
              <Col span={4} className="px-2">
                <Avatar className="pointer" style={{ color: "#fff", backgroundColor: "#1890ff" }}> FR </Avatar>
              </Col>
              <Col span={4} className="px-2">
                <Avatar className="pointer"> EN </Avatar>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col span={12} className="px-2">
                <h5>Affichage des bons et des factures Annulés ?</h5>
              </Col>
              <Col span={4} className="px-2">
                <Avatar 
                  className="pointer" 
                  onClick={() => changeShowMode("YES")}
                  style={{ color: "#fff", backgroundColor: localStorage.getItem("SHOW_MODE") === "YES" ? "#1890ff" :  "#ccc" }}
                > OUI </Avatar>
              </Col>
              <Col span={4} className="px-2">
                <Avatar 
                  onClick={() => changeShowMode("NO")}
                  className="pointer"
                  style={{ color: "#fff", backgroundColor: localStorage.getItem("SHOW_MODE") === "NO" ? "#ff4d4f" :  "#ccc" }}
                > NON </Avatar>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col span={12} className="px-2">
                <h5>Demande de génération de factures réduites ?</h5>
              </Col>
              <Col span={4} className="px-2">
                <Avatar 
                  className="pointer" 
                  onClick={() => changeFactureMode("YES")}
                  style={{ color: "#fff",backgroundColor: localStorage.getItem("SHOW_FAC_MODE") === "YES" ? "#1890ff" :  "#ccc" }}
                > OUI </Avatar>
              </Col>
              <Col span={4} className="px-2">
                <Avatar 
                  onClick={() => changeFactureMode("NO")}
                  className="pointer"
                  style={{ color: "#fff", backgroundColor: localStorage.getItem("SHOW_FAC_MODE") === "NO" ? "#ff4d4f" :  "#ccc" }}
                > NON </Avatar>
              </Col>
            </Row>


          </Card>

      </TabPane>
    </Tabs>
  );
};
export default Parametres;
