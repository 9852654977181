import {
  CheckOutlined,
  DeleteOutlined,
  DollarOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FilePptOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Empty,
  Form,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonCommandeService from "../../../services/bonCommande.service";
import CompteBancaireService from "../../../services/compteBancaire.service";
import FactureAchatService from "../../../services/FactureAchat.service";
import FournisseurService from "../../../services/fournisseur.service";
import PaiementAchatService from "../../../services/paiementAchat.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";

const url = environment.serverUrl;

const DashboardFacture = () => {
  const [commandeForm] = Form.useForm();
  const [paiementForm] = Form.useForm();

  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [bonCommandeModal, setBonCommandeModal] = useState(false);
  const [bonCommandes, setBonCommandes] = useState([]);
  const [paiementsModal, setPaiementsModal] = useState(false);
  const [comptesBancaires, setComptesBancaires] = useState([]);
  const [paiementsList, setPaiementsList] = useState([]);
  const [compteInput, setCompteInput] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [reference, setReference] = useState();
  const [factureInfo, setFactureInfo] = useState({});

  useEffect(() => {
    loadComptesBancaires();
    loadFournisseurs();
    loadFactures();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };

  const loadFactures = async () => {
    setLoading(true);
    await FactureAchatService.getFactureAchats((response) => {
      if (response.status === 200 || 204) {
        FournisseurService.getFournisseurNames((resp) => {
          if (response.status === 200 || 204) {
            initTableData(response.data, resp.data);
          } else message.error("Une erreur est survenue ! ");
        });
      } else message.error("Une erreur est survenue ! ");
    });
    setLoading(false);
  };

  const loadFournisseurs = async (data) => {
    setLoading(true);
  };
  const initTableData = async (data, fournisseurs) => {
    setLoading(true);
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.refBonCommande = await getCommandeReference(
        element.bonCommandeId
      );
      const fournisseurId = element?.facture_achat_produits[0]?.fournisseurId ?? 0;
      element.fournisseurId = fournisseurId;
      element.fournisseur = getFournisseurName(fournisseurId, fournisseurs);
      element.status = getFactureStatus(element.paiement_status);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    setDataTable(list);
    setLoading(false);
  };

  const getFactureStatus = (status) => {
    switch (status) {
      case 1:
        return "Payé";
      case 2:
        return "Payé Partiellement";
      default:
        return "Non Payé";
    }
  };

  const loadComptesBancaires = async () => {
    setLoading(true);
    const response = await CompteBancaireService.getCompteBancaires();
    if (response.status === 200 || 204) setComptesBancaires(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const getCommandeReference = async (bonCommandeId) => {
    const response = await BonCommandeService.getReference(bonCommandeId);
    return response.data.reference;
  };

  const getFournisseurName = (id, list) => {
    for (const element of list) {
      if (element.id === id) return element.raison_sociale;
    }
  };

  const formatteEtat = (etat ,pStatus) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        if( pStatus ) return "Facturé";
        else return "Activé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const changeBonStatus = async (id, etat) => {
    const response = await FactureAchatService.changeEtat(id, etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Activé" : "Desactivé";
      message.info("Facture " + msg);
    } else message.error("Une erreur est survenue ! ");
    loadFactures();
  };

  const navigate = (location) => {
    history.push(location);
  };

  const goToBonReception = (record) => {
    const id = record.id;
    const editMode = false;
    history.push("/factureAchat", { id, editMode });
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    const editMode = true;
    history.push("/factureAchat", { id, editMode });
  };

  const openBonReceptionModal = async () => {
    setLoading(true);
    await BonCommandeService.getActiveBonCommandeForFacturation((response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        for (const element of data) {
          element.showDate = moment(new Date(element.date)).format(
            "YYYY-MM-DD"
          );
        }
        setBonCommandes(response.data);
        setBonCommandeModal(true);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };

  const openPaiementModal = async (record) => {
    setLoading(true);
    const somme = await loadFacturePaiements(record.id);
    const rest = record.total_ttc - somme;
    setFactureInfo({
      id: record.id,
      reference: record.reference,
      montant: record.total_ttc,
      rest: rest.toFixed(3),
      somme: somme.toFixed(3),
      etat: record.etat,
      devise : record.devise
    });
    setPaiementsModal(true);
    setLoading(false);
  };

  const loadFacturePaiements = async (id) => {
    const response = await PaiementAchatService.getActivePaiements(id);
    if (response.status === 200 || 204) {
      const data = response.data;
      var somme_paiement = 0;
      for (const element of data) {
        const nbr = element.id + "";
        element.moyenPaiement = element.moyen_paiement
          ? element.moyen_paiement
          : "Non mentionné";
        const prefix = element.moyenPaiement === "Non mentionné" ? "AC-" : "P-";
        element.reference = prefix + nbr.padStart(4, "0");
        element.showDate = moment(element.date).format("YYYY/MM/DD");
        if (element.date_encaissement) {
          element.showEncaissementDate = moment(
            element.date_encaissement
          ).format("YYYY/MM/DD");
        } else {
          element.showEncaissementDate = element.showDate;
        }
        somme_paiement += element.montant;
      }
      setPaiementsList(data);
      return somme_paiement;
    } else message.error("Une erreur est survenue ! ");
  };
  const deletePaiement = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce paiement ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };

  const deleteItem = async (item) => {
    const response = await PaiementAchatService.deletePaiement(item.id);
    if (response.status === 204 || 200) {
      await loadFacturePaiements(factureInfo.id);
      closePaiementModal();
      message.info("Suppression avec succès");
    } else message.error("Une erreur est survenue ! ");
  };
  const addPaiement = async (values) => {
    const fullyPaied = Number(values.montant) === Number(factureInfo.rest) ? 1 : 2;
    const data = values;
    data.factureAchatId	 = factureInfo.id;
    data.etat = 1;
    data.fullyPaied = fullyPaied;
    const response = await PaiementAchatService.addPaiement(data);

    if (response.status === 200) {
      setPaiementsModal(false);
      loadFactures();
      paiementForm.resetFields();
      message.info("Paiement ajouté avec succès");
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference);
  };

  const downloadFile = () => {
    window.open(url + "Files/Achat/Facture/" + reference + ".pdf", "download");
  };

  const selectChange = () => {
    const paiement = paiementForm.getFieldsValue();
    const moyen_paiement = paiement.moyen_paiement;
    const moyens = ["Carte Bancaire", "Chèque", "Virement"];
    if (moyens.includes(moyen_paiement)) setCompteInput(true);
    else setCompteInput(false);
  };

  const checkPaiement = () => {
    return factureInfo.etat !== 1 || Number(factureInfo.rest) === 0;
  };

  const bonCommandeCols = [
    {
      title: "Référence Bon de commande",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.showFournisseur.localeCompare(b.showFournisseur),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) => a.devise - b.devise,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => goToBonReception(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const paiementsCols = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Moyen de paiement",
      dataIndex: "moyenPaiement",
      sorter: (a, b) => a.moyenPaiement.localeCompare(b.moyenPaiement),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Date d'encaissement",
      dataIndex: "showEncaissementDate",
      sorter: (a, b) =>
        a.showEncaissementDate.localeCompare(b.showEncaissementDate),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant",
      render: (text, record) => <b>{Number(record.montant).toFixed(3)} </b>,
      sorter: (a, b) => a.montant - b.montant,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => deletePaiement(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const facturesCols = [
    {
      title: "Référence Facture",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Référence Bon de commande",
      dataIndex: "refBonCommande",
      sorter: (a, b) => a.refBonCommande.localeCompare(b.refBonCommande),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Fournisseur",
      dataIndex: "fournisseur",
      sorter: (a, b) => a.fournisseur.localeCompare(b.fournisseur),
    },{
      title: "Montant TTC",
      dataIndex : "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a.devise.localeCompare(b.devise),
    },{
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={!Can("12_4") || record.paiement_status}>
              <Menu.Item
                className="px-5"
                key="1"
                disabled={record.etat === 1}
                onClick={() => changeBonStatus(record.id, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="2"
                disabled={record.etat === -1}
                onClick={() => changeBonStatus(record.id, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat , record.paiement_status)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.etat - b.etat,
    },
    {
      title: "Etat Paiement",
      dataIndex: "Etat_Paiement",
      render: (text, record) => (
        <>
          {record.status === "Payé" && <Tag color="#2db7f5">Payé</Tag>}
          {record.status === "Payé Partiellement" && (
            <Tag color="#87d068">Payé Partiellement</Tag>
          )}
          {record.status === "Non Payé" && <Tag color="#f50">Non Payé</Tag>}
        </>
      ),
      sorter: (a, b) => a.paiement_status - b.paiement_status,
    },
    {
      title: "Action",
      key: "action",
      width: "13%",

      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("12_5")}
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              disabled={record.etat === 1 || !Can("12_3")}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Ajouter un règlement">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("12_9")}
              onClick={() => openPaiementModal(record)}
              icon={<DollarOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    commandeForm.resetFields();
    setBonCommandeModal(false);
  };
  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };
  const closePaiementModal = () => {
    setPaiementsModal(false);
    paiementForm.resetFields();
  };

  return (
    <>
      {Can("12_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Factures Des Achats"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("12_2")}
                onClick={() => openBonReceptionModal()}
              >
                Ajouter Une Facture
              </Button>
            }
          >
            <TableWithFiltres data={dataTable} columns={facturesCols} />
          </Card>
          <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">Bon de commande : {reference}</div>
                  <div className="px-5 ">
                    <Button
                      onClick={() => downloadFile()}
                      icon={<DownloadOutlined />}
                    >
                      Télécharger
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={1000}
            onCancel={() => setShowModal(false)}
          >
            {showModal && (
              <div className="heigt-500px">
                <PdfViewerModal
                  diractory="Files/Achat/Facture/"
                  reference={reference}
                ></PdfViewerModal>
              </div>
            )}
          </Modal>
          <Modal
            visible={bonCommandeModal}
            getContainer={false}
            centered
            onOk={commandeForm.submit}
            onCancel={handleCancel}
            title={"Liste des bons de commandes"}
            width={900}
          >
            <Spin spinning={isLoading} size="large">
              <TableWithFiltres data={bonCommandes} columns={bonCommandeCols} />
            </Spin>
          </Modal>

          <Modal
            visible={paiementsModal}
            getContainer={false}
            centered
            onOk={paiementForm.submit}
            onCancel={() => closePaiementModal()}
            title={"Paiements"}
            width={900}
          >
            <Row>
              <Col span={6}>
                <b>Facture : {factureInfo.reference} </b>
              </Col>
              <Col span={6}>
                <b>Total TTC : {factureInfo.montant}</b>
              </Col>
              <Col span={6}>
                <b>Total Payé : {factureInfo.somme}</b>
              </Col>
              <Col span={6}>
                <b>Reste à payé :<span className="text-success"> {factureInfo.rest} </span></b>
              </Col>
            </Row>
            <hr />
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Liste des paiements" key="1">
                <Table
                  expandable={{
                    expandedRowRender: (record) => (
                      <p className="px-1">
                        <b> Description : </b>
                        {record.description}
                      </p>
                    ),
                    rowExpandable: (record) => record.description !== null,
                  }}
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={paiementsCols}
                  dataSource={paiementsList}
                  pagination={{ position: ["bottomCenter"], pageSize: 5 }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                disabled={checkPaiement()}
                tab="Nouveau paiement"
                key="2"
              >
                <Form
                  layout="vertical"
                  form={paiementForm}
                  onFinish={addPaiement}
                >
                  <Row>
                    <Col span={24} className="px-2">
                      <Form.Item label="Type de paiement" name="moyen_paiement">
                        <Select size="large" onChange={() => selectChange()} dropdownStyle = {{ position: "fixed" }}>
                          <Select.Option key="1" value="Espece">
                            Espèce
                          </Select.Option>
                          <Select.Option key="2" value="Carte Bancaire">
                            Carte Bancaire
                          </Select.Option>
                          <Select.Option key="3" value="Chèque">
                            Chèque
                          </Select.Option>
                          <Select.Option key="4" value="Virement">
                            Virement
                          </Select.Option>
                          <Select.Option key="5" value="Effet">
                            Effet
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Montant"
                        name="montant"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={factureInfo.rest}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder=" "
                          size="large"
                          className="w-100"
                        ></DatePicker>
                      </Form.Item>
                    </Col>
                    {compteInput && (
                      <>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Compte Bancaire"
                            name="compteBancaireId"
                          >
                            <Select
                              size="large"
                              onChange={() => selectChange()}
                            >
                              {comptesBancaires &&
                                comptesBancaires.map((compte) => (
                                  <Select.Option
                                    key={compte.id}
                                    value={compte.id}
                                  >
                                    {compte.designation}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Date d'encaissement"
                            name="date_encaissement"
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=" "
                              size="large"
                              className="w-100"
                            ></DatePicker>
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={24} className="px-2">
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            message:
                              "Champ obligatoire avec maximum de caractère 255 !",
                            max: 254,
                          },
                        ]}
                      >
                        <TextArea rows={3} type="text" maxLength={255} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Modal>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardFacture;
